import type { SidebarProps } from '.';
import type { SidebarPage, HTTPMethod } from '@readme/iso';

import path from 'path';

import { getChildrenPages, hasChildrenPages, isEmptyParentPage } from '@readme/iso';
import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Method from '@ui/API/Method';
import PageNavItemErrorBadge from '@ui/Dash/PageNav/Item/ErrorBadge';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

export interface SidebarListSharedProps {
  /**
   * Slug for the current page.
   */
  activeDoc?: string;
  activeItemRef: React.RefObject<HTMLAnchorElement>;
  alwaysExpanded: boolean;
  children?: React.ReactNode;

  /**
   * Optional custom icon render function for the sidebar list items.
   */
  customIconRender: SidebarProps['customIconRender'];
  linkClass?: string;

  /**
   * Sidebar link prefix.
   */
  pathRoot?: string;
}

interface SidebarListProps extends SidebarListSharedProps {
  className?: string;
  pages: SidebarPage[];
}

interface SidebarListItemProps extends SidebarListSharedProps {
  page: SidebarPage;
}

const SidebarListItem = ({
  activeItemRef,
  activeDoc: reqSlug,
  alwaysExpanded = false,
  customIconRender,
  page,
  children,
  pathRoot,
  linkClass = '',
}: SidebarListItemProps) => {
  const bem = useClassy(classes, 'Sidebar');

  // Check presence of properties in page object to avoid type errors
  const icon = 'icon' in page ? page.icon : '';
  const isReference = 'isReference' in page ? page.isReference : false;
  const pageType = 'pageType' in page ? page.pageType : undefined;

  const { deprecated, link_external: linkExternal, link_url: linkUrl, slug: pageSlug, title, type } = page;
  const { slug: routeSlug } = useParams<{ slug?: string }>();
  const [isExpanded, setIsExpanded] = useState(alwaysExpanded);
  const [linkClasses, setLinkClasses] = useState(linkClass);

  // If the page is an empty parent page, let's link to the first child instead
  const isEmptyParent = isEmptyParentPage(page);
  const childrenPages = useMemo(() => getChildrenPages(page), [page]);
  const slugToLinkTo = isEmptyParent ? childrenPages[0].slug : pageSlug;
  const sendToChildLink = isEmptyParent && childrenPages[0].type === 'link';
  const isLinkType = type === 'link';
  const openLinkInNewTab = isLinkType && linkExternal;
  const linkPrefix = pathRoot || (isReference ? 'reference' : 'docs');

  let linkToValue;
  if (isLinkType) {
    linkToValue = linkUrl;
  } else if (sendToChildLink) {
    linkToValue = childrenPages[0].link_url;
  } else {
    linkToValue = path.join('/', linkPrefix, pageType === 'RealtimePage' ? 'intro' : '', slugToLinkTo);
  }

  const isActive = pageSlug === (routeSlug || reqSlug);

  useEffect(() => {
    const slugExistsInChildren = (child, targetSlug) => {
      if (!child || child?.length === 0) return false;

      return child.some(c => {
        if (c.slug === targetSlug) return true;

        // Recursive check
        return slugExistsInChildren(getChildrenPages(c), targetSlug);
      });
    };

    const hasChild = slugExistsInChildren(childrenPages, routeSlug || reqSlug);

    // Force-set an .active class on the first doc
    // when visiting the slug-less top-level route.
    if (!routeSlug && isActive) setLinkClasses(`${linkClass} active`);
    else setLinkClasses(linkClass);

    // Toggle subnav lists expansion.
    setIsExpanded(alwaysExpanded || (children && (isActive || hasChild)) || false);
  }, [alwaysExpanded, children, isActive, linkClass, childrenPages, pageSlug, reqSlug, routeSlug]);

  const Link =
    !isLinkType && !sendToChildLink
      ? NavLink
      : ({ to, isActive: isLinkActive, ...props }) => (
          <a {...props} data-active={isLinkActive ? '' : undefined} href={to}>
            {props.children}
          </a>
        );

  /**
   * Soft-toggle a list item group without
   * navigating to the parent page.
   */
  const softToggle = e => {
    e.preventDefault();
    if (!alwaysExpanded) setIsExpanded(!isExpanded);
  };

  const customIcon = useMemo(() => {
    if (typeof customIconRender === 'function') {
      const custom = customIconRender(page);

      if (custom) {
        return <div className={bem('-link-pageIconCustom')}>{custom}</div>;
      }
    }

    return null;
  }, [bem, customIconRender, page]);

  const pageIcon = useMemo(() => {
    if (customIcon) return customIcon;

    if (!icon?.length) return null;

    return <i className={bem('-link-pageIcon', icon)} />;
  }, [bem, customIcon, icon]);

  const apiMethod = 'api' in page ? page.api?.method : 'api_method' in page ? page.api_method : null;

  const isRenderable = ('renderable' in page && page.renderable?.status) || true;

  return (
    <li className={bem('Sidebar-item', isExpanded && 'subnav-expanded')}>
      <Link
        {...(isActive ? { ref: activeItemRef } : null)}
        className={bem(
          '-link',
          children && '-link_parent',
          deprecated && '-link_deprecated',
          page.hidden && '-link_hidden',
          !isRenderable && '-link_error',
          linkClasses,
          'text-wrap',
          'rm-Sidebar-link',
        )}
        // We should never set the `active` class on empty parent pages!
        isActive={match => (match && !isEmptyParent) || false}
        rel={openLinkInNewTab ? 'noopener' : undefined}
        target={openLinkInNewTab ? '_blank' : '_self'}
        to={linkToValue}
      >
        <span className={bem('-link-text')}>
          {!!children && (
            <button
              aria-expanded={isExpanded}
              aria-label={`${isExpanded ? 'Hide' : 'Show'} subpages for ${title}`}
              className={bem('-link-buttonWrapper')}
              onClick={softToggle}
              onKeyDown={e => [' ', 'Enter'].includes(e.key) && softToggle(e)}
            >
              <i className={bem('-link-expandIcon', 'icon-chevron-rightward')} />
            </button>
          )}
          {pageIcon}
          <span className={bem('-link-text_label')}>{title}</span>
          {!!deprecated && (
            <Tooltip
              arrow={false}
              content={`Deprecated ${page.type === 'endpoint' ? 'Endpoint' : 'Doc'}`}
              interactive={false}
              placement="bottom"
            >
              <Icon
                aria-label="Deprecated"
                color="color-text-minimum"
                name="alert-circle"
                size="md"
                tabIndex={-1}
                wrapperClassName={bem('-link_deprecated-icon')}
              />
            </Tooltip>
          )}
        </span>
        {!!isLinkType && <i aria-hidden="true" className={bem('-link-externalIcon', 'icon-arrow-up-right')} />}
        {(page.type === 'endpoint' || page.type === 'webhook') && !!apiMethod && pageType !== 'RealtimePage' && (
          <span className={bem('-method-container')}>
            {type === 'webhook' && <Icon name="webhook" />}
            <Method className={bem('-method')} fixedWidth type={apiMethod.toLowerCase() as HTTPMethod} />
          </span>
        )}
        {!isRenderable && <PageNavItemErrorBadge />}
      </Link>
      {children}
    </li>
  );
};

const SidebarList = ({
  activeItemRef,
  alwaysExpanded = false,
  className = '',
  linkClass = '',
  pages,
  ...rest
}: SidebarListProps) => {
  const bem = useClassy(classes, 'Sidebar');
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);

  return (
    <ul className={bem(className, '-list', 'rm-Sidebar-list')}>
      {pages.map(page => {
        return hasChildrenPages(page, isSuperHubAdmin) ? (
          <SidebarListItem
            key={`page-${page.slug}`}
            activeItemRef={activeItemRef}
            alwaysExpanded={alwaysExpanded}
            page={page}
            {...rest}
          >
            <SidebarList
              activeItemRef={activeItemRef}
              alwaysExpanded={alwaysExpanded}
              className="subpages"
              pages={getChildrenPages(page)}
              {...rest}
            />
          </SidebarListItem>
        ) : (
          <SidebarListItem
            key={`page-${page.slug}`}
            activeItemRef={activeItemRef}
            alwaysExpanded={alwaysExpanded}
            linkClass={`childless ${className === 'subpages' ? 'subpage' : linkClass}`}
            page={page}
            {...rest}
          />
        );
      })}
    </ul>
  );
};

export default SidebarList;
