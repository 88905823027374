import React from 'react';

import useClassy from '@core/hooks/useClassy';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';

import { useCustomBlockFormContext } from '../Context';

import classes from './style.module.scss';

export default function ComponentEditor() {
  const bem = useClassy(classes, 'ComponentEditor');
  const { control } = useCustomBlockFormContext();

  return (
    <div className={bem('&')} data-testid="ComponentEditor">
      <div className={bem('-inputWrapper')} data-label="index.mdx">
        <RHFGroup control={control} name="source" required>
          {({ field }) => (
            <CodeInput {...field} className={bem('-codeInput')} initialValue={field.value} language="jsx" />
          )}
        </RHFGroup>
      </div>
    </div>
  );
}
