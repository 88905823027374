import type { RenderElementProps } from 'slate-react';

import React, { useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import { useEmojiMenu } from '@ui/MarkdownEditor/editor/EmojiMenu';
import { usePageMenu } from '@ui/MarkdownEditor/editor/PageMenu';
import { useReusableContentMenu } from '@ui/MarkdownEditor/editor/ReusableContentMenu';
import { useVariableMenu } from '@ui/MarkdownEditor/editor/VariableMenu';
import { MenuActionTypes, MenuHandleTypes } from '@ui/MarkdownEditor/enums';
import type { MenuHandle as MenuHandleType } from '@ui/MarkdownEditor/types';

type Props = RenderElementProps & {
  element: MenuHandleType;
};

const menus = {
  [MenuHandleTypes.variable]: useVariableMenu,
  [MenuHandleTypes.mdxVariable]: useVariableMenu,
  [MenuHandleTypes.reusableContent]: useReusableContentMenu,
  [MenuHandleTypes.link]: usePageMenu,
  [MenuHandleTypes.emoji]: useEmojiMenu,
  [MenuHandleTypes.glossary]: useReusableContentMenu,
};

const useMenu = (type: MenuHandleTypes) => {
  return menus[type]();
};

const MenuHandle = ({ attributes, children, element }: Props) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const [{ open, target }, dispatch] = useMenu(element.menuType);
  const ref = attributes.ref;

  useEffect(() => {
    if (!open && !target && ref.current) {
      const rangeRef = Editor.rangeRef(editor, Editor.range(editor, ReactEditor.findPath(editor, element)));

      dispatch({ type: MenuActionTypes.init, payload: { rangeRef } });
      dispatch({ type: MenuActionTypes.open, payload: { target: ref } });
    }
  }, [dispatch, editor, element, open, ref, target]);

  useEffect(() => {
    dispatch({ type: MenuActionTypes.search, payload: element.search });
  }, [dispatch, element.menuType, element.search]);

  useEffect(() => {
    if (open && !selected && target === ref) {
      dispatch({ type: MenuActionTypes.close });
      const path = ReactEditor.findPath(editor, element);
      Transforms.unwrapNodes(editor, { at: path });
    }
  }, [dispatch, editor, element, open, ref, selected, target]);

  useEffect(() => {
    return () => {
      dispatch({ type: MenuActionTypes.close });
    };
  }, [dispatch, element.menuType]);

  return (
    <span {...attributes} role="button">
      {children}
    </span>
  );
};

export default MenuHandle;
