import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { APIDefinitionUploadType, StagedAPIDefinitionType } from '@readme/api/src/mappings/apis/types';

import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';
import type { HTTPError } from '@core/utils/types/errors';

import Button from '@ui/Button';
import FileUploader from '@ui/FileUploader';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';

import styles from './index.module.scss';

interface ApiDefinitionImporterFields {
  schema?: File;
  url?: string;
}

interface ApiDefinitionImporterProps {
  className?: string;

  /**
   * API definition filename that will be updated and replaced by this form.
   * When omitted, a new API definition will be created instead.
   */
  filename?: string;

  /**
   * Invoked whenever an import was successfully completed.
   */
  onImport?: (response: StagedAPIDefinitionType) => void;

  /**
   * Prefills the URL import field with this value. Useful when editing an
   * existing API definition that was previously imported by URL.
   */
  url?: string;
}

/**
 * Renders an upload form to let users import an API Definition schema either by
 * file or an external URL. When form is submitted, request is sent to API v2 to
 * process the file or URL being imported.
 */
function ApiDefinitionImporter({ className, filename, onImport, url }: ApiDefinitionImporterProps) {
  const bem = useClassy(styles, 'ApiDefinitionImporter');
  const formRef = useRef<HTMLFormElement>(null);
  const [createDefinition, updateDefinition] = useSuperHubStore(s => [
    s.apiDefinitions.createDefinition,
    s.apiDefinitions.updateDefinition,
  ]);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
  } = useForm<ApiDefinitionImporterFields>({
    defaultValues: { url },
  });

  const onSubmit = handleSubmit(async data => {
    // Prepare data to be sent to our API endpoint.
    let payload: APIDefinitionUploadType;

    if (data.schema) {
      payload = { schema: data.schema };
    } else {
      if (!data.url) {
        setError('url', {
          type: 'required',
          message: 'Required',
        });
        return;
      }
      payload = { url: data.url };
    }

    try {
      const stagedResponse = filename ? await updateDefinition(filename, payload) : await createDefinition(payload);

      onImport?.(stagedResponse.data);
      reset();
    } catch (error) {
      reset();

      // Propagate any errors that come back from the API. This must be called
      // *after* resetting the form.
      const { info } = error as HTTPError<ErrorType>;
      if (info) {
        const infoError = info.errors?.shift() || {
          key: Object.keys(payload)[0],
          message: info.title,
        };

        if (infoError.key === 'schema' || infoError.key === 'url') {
          setError(infoError.key, {
            message: infoError.message,
            type: 'value',
          });
        }
      }
    }
  });

  return (
    <form ref={formRef} className={bem('&', className)} onSubmit={onSubmit}>
      <Flex align="stretch" className={bem('-list')} gap="0" layout="col">
        <RHFGroup className={bem('-item')} control={control} name="schema">
          {({ field }) => (
            <Flex align="stretch" gap="sm">
              <Flex gap="0" layout="col">
                <div className={bem('-item-name')}>Upload File</div>
                <div className={bem('-item-description')}>.yaml or .json</div>
              </Flex>
              <FileUploader
                accept="application/json,.yaml"
                className={bem('-item-upload')}
                fullWidth
                kind="secondary"
                onUpload={async file => {
                  field.onChange(file);
                  return onSubmit();
                }}
                outline
              >
                Choose
              </FileUploader>
            </Flex>
          )}
        </RHFGroup>
        <RHFGroup className={bem('-item')} control={control} isUrl name="url">
          {({ field }) => (
            <Flex align="stretch" gap="xs" layout="col">
              <div className={bem('-item-name')}>Import from URL</div>
              <Flex gap="xs">
                <Input className={bem('-item-input')} placeholder="https://" {...field} size="sm" />
                <Button ghost loading={!!field.value && isSubmitting} size="sm" type="submit">
                  Import
                </Button>
              </Flex>
            </Flex>
          )}
        </RHFGroup>
      </Flex>
    </form>
  );
}

export default ApiDefinitionImporter;
