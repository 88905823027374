import type CodeMirror from 'codemirror';

import React, { useEffect, useMemo, memo, forwardRef } from 'react';

import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const syntaxHighlighter = typeof window !== 'undefined' ? require('@readme/syntax-highlighter/dist').default : () => {};

// @fixme: Ugh. The types for CodeMirror.{Editor,Doc} appear to be missing
// attributes?!
export interface CMEditor extends CodeMirror.Editor {
  doc: CodeMirror.Doc & {
    children: [
      {
        lines: [
          {
            text: string;
          },
        ];
      },
    ];
    sel: {
      ranges: [
        {
          anchor: {
            ch: number;
            line: number;
          };
          head: {
            ch: number;
            line: number;
          };
        },
      ];
    };
    size: number;
  };
}

export type CodeSnippetProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  code: string;
  editorProps?: {
    editorDidMount?: (codeEditor: ReturnType<typeof CodeMirror>) => void;
    onChange?: (event: Event, data: unknown, value: string) => void;
    onKeyDown?: (codeMirrorEditor: CMEditor, event: KeyboardEvent) => void;
  };
  footer?: React.ReactElement;
  header?: React.ReactElement;
  language: string;
  onSnippetUpdate?: (str: string) => void;
  options?: {
    customTheme?: 'material-palenight' | 'neo' | 'tomorrow-night';
    dark?: boolean;
    editable?: boolean;
    foldGutter?: boolean;
    highlightMode?: boolean;
    inputStyle?: 'contenteditable' | 'textarea';
    ranges?: { ch: number; line: number }[];
    readOnly?: boolean;
    tokenizeVariables?: boolean;
  };
};

const CodeSnippet = memo(
  forwardRef(function CodeSnippet(
    { className, header, code, language, options, editorProps, onSnippetUpdate, footer, ...args }: CodeSnippetProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) {
    const snippet = useMemo(
      () => syntaxHighlighter(code, language, options, editorProps),
      [code, language, options, editorProps],
    );

    useEffect(() => {
      if (onSnippetUpdate) {
        onSnippetUpdate(snippet);
      }
    }, [snippet, onSnippetUpdate]);

    return (
      <div {...args} ref={ref} className={className || ''} role="none">
        {header}
        <pre className="CodeSnippet">{snippet}</pre>
        {footer}
      </div>
    );
  }),
);

export default CodeSnippet;
