import React from 'react';

import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD/RDMD';

import classes from './style.module.scss';

const CopilotInstalledPage = (props: { content: string }) => {
  const { content } = props;
  return (
    <Flex className={classes.CopilotInstalledPage} justify="center">
      <RDMD className={classes['CopilotInstalledPage-content']}>{content}</RDMD>
    </Flex>
  );
};

export default CopilotInstalledPage;
