import type { ParameterObject } from 'oas/types';
import type { OpenAPIV3 } from 'openapi-types';

import produce from 'immer';
import Oas from 'oas';
import React, { useCallback, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store';
import { upperFirst } from '@core/utils/lodash-micro';

import APISectionHeader from '@ui/API/SectionHeader';
import type { SchemaFormat } from '@ui/APIDesigner/TypeMenu';
import TypeMenu, { renderSchemaType } from '@ui/APIDesigner/TypeMenu';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';

import useApiDesignerValidation from '../hooks/useApiDesignerValidation';
import classes from '../style.module.scss';

interface SchemaTypes {
  allOtherParameters: string[];
  id: string;
  onChange: (schema: SchemaTypes['schema'], idx: string) => void;
  schema: {
    default?: string;
    description?: string;
    format?: SchemaFormat;
    in: Exclude<ParameterObject['in'], 'cookie'>;
    items?: OpenAPIV3.ArraySchemaObject['items'] & OpenAPIV3.SchemaObject;
    name: string;
    required: boolean;
    type: NonNullable<OpenAPIV3.SchemaObject['type']>;
  };
}

const Schema = ({ allOtherParameters, id, onChange, schema }: SchemaTypes) => {
  const bem = useClassy(classes, 'Parameter');
  const [removeParameter] = useAPIDesignerStore(s => [s.removeParameter]);

  const handleChange = useCallback(
    (value, kind: keyof SchemaTypes['schema']) => {
      const updatedSchema = { ...schema };
      if (kind === 'name') updatedSchema.name = value;
      if (kind === 'description') updatedSchema.description = value;
      if (kind === 'required') updatedSchema.required = !schema.required;
      if (kind === 'default') updatedSchema.default = value;
      if (kind === 'type') {
        updatedSchema.type = value.type;
        updatedSchema.format = value.format;
        updatedSchema.items = value.items;
      }

      onChange(updatedSchema, id);
    },
    [id, onChange, schema],
  );

  const { error: nameError, field: nameFieldProps } = useApiDesignerValidation<string>({
    name: `parameter-${schema.in}-${id}`,
    validate: {
      beforeChange: (value: string) => {
        const isUnique = !allOtherParameters?.includes(value);
        return isUnique || `There is already a parameter with the name '${value}'. Please choose a different name.`;
      },
      required: 'Parameter name is required',
    },
    value: schema.name,
    handleChange: val => handleChange(val, 'name'),
  });

  return (
    <Flex align="stretch" className={bem('&')} gap="4px" justify="start" layout="col">
      <Flex align="stretch" gap="0" layout="col">
        <div className={bem('-group')}>
          <Flex align="baseline" gap="2px" justify="start">
            <div className={bem('-inputSizer')}>
              <input
                className={bem('-input', '-input_name', nameError && '-input_error')}
                data-1p-ignore
                disabled={schema.in === 'path'}
                placeholder="Name"
                required
                spellCheck="false"
                {...nameFieldProps}
              />
              <span aria-hidden="true" className={bem('-inputSizer-clone')}>
                {nameFieldProps.value}
              </span>
            </div>

            <Dropdown justify="start">
              <span className={bem('-input', '-input_type')}>
                {renderSchemaType(schema)}
                <Icon name="chevron-down" />
              </span>
              <TypeMenu
                format={schema.format}
                items={schema.items}
                setNewType={newType => handleChange(newType, 'type')}
                shouldShowAllowObject={false}
                type={schema.type || 'string'}
              />
            </Dropdown>

            {schema.in !== 'path' && (
              <Flex
                className={bem('-input', '-input_required', schema.required && '-input_required_checked')}
                gap="xs"
                tag="label"
              >
                <span>required</span>
                <input
                  checked={schema.required}
                  className={bem('-input-checkbox')}
                  onChange={e => handleChange(e.target.value, 'required')}
                  type="checkbox"
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="2px">
            <input
              className={bem('-input', '-input_form')}
              name="default"
              onChange={e => handleChange(e.target.value, 'default')}
              placeholder="Default Value"
              value={schema.default}
            />
            {schema.in !== 'path' && (
              <Dropdown>
                <Button ghost kind="minimum" size="xs">
                  <Icon name="more-vertical" />
                </Button>
                <Menu>
                  <MenuItem color="red" icon="icon icon-trash1" onClick={() => removeParameter(schema.in, schema.name)}>
                    Delete
                  </MenuItem>
                </Menu>
              </Dropdown>
            )}
          </Flex>
        </div>
        <input
          className={bem('-description')}
          onChange={e => handleChange(e.target.value, 'description')}
          placeholder="Description"
          value={schema.description || ''}
        />
      </Flex>
      {nameError ? <span className={bem('-error')}>{nameError}</span> : null}
    </Flex>
  );
};

interface ParameterGroupProps {
  in: Exclude<ParameterObject['in'], 'cookie'>;
}

const ParameterGroup = (props: ParameterGroupProps) => {
  const [apiObject, setApiObject, addEmptyParameter] = useAPIDesignerStore(s => [
    s.apiObject,
    s.setApiObject,
    s.addEmptyParameter,
  ]);
  const oas = new Oas(apiObject!.schema);
  const operation = oas.operation(apiObject!.path, apiObject!.method);
  const schemas = useMemo(() => operation.schema.parameters || [], [operation.schema.parameters]) as ParameterObject[];

  const onChange = useCallback(
    (newSchema: SchemaTypes['schema'], idx: string) => {
      // keeps track of which parameter you are editing within the group.
      // ie the 2nd query parameter, the 3rd header parameter, etc.
      const index = Number(idx);

      // convert newSchema to OpenAPIV3_1.ParameterObject
      const newSchemaObject: ParameterObject = {
        name: newSchema.name,
        in: newSchema.in,
        required: newSchema.required,
        description: newSchema.description,
        schema: {
          type: newSchema.type,
          format: newSchema.format,
          default: newSchema.default,
          items: newSchema.type === 'array' ? newSchema.items : undefined,
        } as OpenAPIV3.SchemaObject,
      };

      // Since all types of params are mixed together in oas we need to make sure
      // that we edit the correct one, since the index is based on the location
      const modifiedSchemas = produce(schemas, draft => {
        // `counterInLocation` keeps track of how many times a parameter type (query, header)
        // in the `schemas` array matches the one you are editing
        let counterInLocation = 0;
        draft.forEach((param, i) => {
          if (param.in === newSchema.in) {
            if (counterInLocation === index) {
              draft[i] = newSchemaObject;
            }

            counterInLocation += 1;
          }
        });
      });

      const newOas = produce(apiObject!.schema, draft => {
        draft.paths![apiObject!.path]![apiObject!.method]!.parameters = modifiedSchemas;
      });
      setApiObject({ ...apiObject!, schema: newOas });
    },
    [apiObject, setApiObject, schemas],
  );

  const paramsOfType = schemas.filter(param => 'in' in param && param.in === props.in);

  return (
    <section className={classes.ParameterGroup}>
      <APISectionHeader className={classes['ParameterGroup-header']} heading={`${upperFirst(props.in)} Parameters`}>
        {/* TODO: disable with a tooltip? */}
        {props.in !== 'path' && (
          <>
            <span className={classes['ParameterGroup-divider']} />
            <Button kind="secondary" onClick={() => addEmptyParameter(props.in)} outline size="xs">
              <Icon name="plus" />
            </Button>
          </>
        )}
      </APISectionHeader>

      <div>
        {paramsOfType.map((param, idx, parameters) => {
          // TODO: yay refs
          const parameterObject = param;
          const paramSchema: SchemaTypes['schema'] = {
            name: parameterObject.name,
            description: parameterObject.description || '',
            required: parameterObject.required || false,
            in: props.in,
            type:
              (parameterObject.schema && 'type' in parameterObject.schema && parameterObject.schema.type) || 'string',
            default:
              (parameterObject.schema && 'default' in parameterObject.schema && parameterObject.schema.default) || '',
            format:
              (parameterObject.schema &&
                'format' in parameterObject.schema &&
                (parameterObject.schema.format as SchemaFormat)) ||
              undefined,
            items:
              (parameterObject.schema && 'items' in parameterObject.schema && parameterObject.schema.items) ||
              undefined,
          };

          const allOtherParameters = parameters.filter((_, i) => i !== idx).map(p => p.name);

          return (
            <Schema
              key={idx.toString()}
              allOtherParameters={allOtherParameters}
              id={idx.toString()}
              onChange={onChange}
              schema={paramSchema}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ParameterGroup;
