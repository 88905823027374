import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import useReadmeApi from '@core/hooks/deprecated/useReadmeApi';

import Avatar from '@ui/Avatar';

import useApiActions from '../../useApiActions';
import { TutorialProp } from '../Modal/proptypes/tutorials';

import classes from './style.module.scss';

const {
  utils: { BaseUrlContext },
} = require('@readme/markdown');

export default function TutorialTile({ children, className, fetchLatest, openTutorial, tutorial }) {
  const [displayCallout, toggleCallout] = useState(true);
  const baseUrl = useContext(BaseUrlContext);
  const [currentTutorial, setCurrentTutorial] = useState(tutorial);
  const { backgroundColor, emoji, title, slug } = currentTutorial;
  const ApiActions = useApiActions();
  const { initRequest, response } = useReadmeApi(baseUrl.replace(/\/$/, ''));

  // If the recipe object contains a `link`, that means that the fetch
  // for the recipe data either failed or hasn't happened yet.
  // As a fallback, let's just open the `link` in a new tab/window.
  const openTutorialWithFallback = () =>
    currentTutorial.link ? window.open(currentTutorial.link, '_blank') : openTutorial({ tutorial: currentTutorial });

  useEffect(() => {
    if (fetchLatest && slug) ApiActions.getTutorial(initRequest, slug);
  }, [ApiActions, fetchLatest, initRequest, slug]);

  useEffect(() => {
    if (response.tutorial) setCurrentTutorial(response.tutorial);
  }, [response.tutorial]);

  useEffect(() => {
    if (!fetchLatest) setCurrentTutorial({ ...tutorial });
  }, [fetchLatest, tutorial]);

  return (
    <div
      className={`${className} ${classes.TutorialTile}`}
      onClick={() => openTutorialWithFallback()}
      onKeyDown={() => openTutorialWithFallback()}
      role="button"
      tabIndex={0}
    >
      <div className={classes['TutorialTile-Body']}>
        {!!emoji && (
          <Avatar className={classes['TutorialTile-Body-Avatar']} hexColor={backgroundColor} size="sm">
            {emoji}
          </Avatar>
        )}
        <div className={classes['TutorialTile-Body-Text']}>
          <div className={classes['TutorialTile-Body-Text-Title']}>{title}</div>
          <div className={classes['TutorialTile-Body-Text-Action']}>
            Open Recipe
            <i className={[`${classes['TutorialTile-Body-Text-Action-Arrow']} icon-chevron-rightward`].join(' ')} />
          </div>
        </div>
      </div>
      {!!displayCallout && !!children && (
        <div
          className={classes['TutorialTile-Notification']}
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
          role="button"
          tabIndex={-1}
        >
          {children}
          <button
            className={classes['TutorialTile-Notification-Dismiss']}
            onClick={() => toggleCallout(false)}
            type="button"
          >
            Dismiss
          </button>
        </div>
      )}
    </div>
  );
}

TutorialTile.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fetchLatest: PropTypes.bool,
  openTutorial: PropTypes.func,
  tutorial: TutorialProp,
};

TutorialTile.defaultProps = {
  className: '',
  fetchLatest: false,
  openTutorial: () => {},
};
