import React, { memo, forwardRef, useImperativeHandle, useCallback, useEffect, useRef } from 'react';

import classy from '@core/utils/classy';

import './style.scss';

/**
 * A simple toggle.
 */

export interface ToggleProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  checked?: boolean;
  children?: React.ReactNode;
  className?: string;
  defaultChecked?: boolean;
  description?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  isIndeterminate?: boolean;
  isLabelMuted?: boolean;
  kind?: 'default' | 'red-green';
  label?: React.ReactNode;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  size?: 'lg' | 'md' | 'sm';
  style?: React.CSSProperties;
  type?: 'checkbox' | 'radio' | 'toggle';
  value?: string;
}

const Toggle = memo(
  forwardRef<HTMLInputElement | null, ToggleProps>(
    (
      {
        children,
        className,
        description,
        isIndeterminate = false,
        isLabelMuted = false,
        label,
        onChange,
        size = 'md',
        style,
        type = 'checkbox',
        kind = 'default',
        required,
        ...attrs
      }: ToggleProps,
      ref,
    ) => {
      const inputRef = useRef<HTMLInputElement>(null);
      useImperativeHandle(ref, () => inputRef?.current as HTMLInputElement);

      const toggleClasses = classy(
        'Toggle',
        `Toggle_${type}`,
        type === 'toggle' && `Toggle_toggle_${size}`,
        type === 'toggle' && kind !== 'default' && `Toggle_${kind}`,
        className,
      );

      const handleChange = useCallback(
        event => {
          onChange?.(event);
        },
        [onChange],
      );

      useEffect(() => {
        if (type === 'checkbox' && inputRef?.current) {
          inputRef.current.indeterminate = isIndeterminate;
        }
      }, [isIndeterminate, type]);

      return (
        <label className={toggleClasses} style={style}>
          <input
            ref={inputRef}
            required={required}
            {...attrs}
            className="Toggle-input"
            onChange={handleChange}
            type={type === 'toggle' ? 'checkbox' : type}
          />
          <div className="Toggle-display">
            <i className="Toggle-display-icon icon-check-heavy" />
            <i className="Toggle-display-icon icon-minus-heavy" />
          </div>
          {!!(label || children) && (
            <span className={classy('Toggle-label', `Toggle-label_${size}`, isLabelMuted && 'Toggle-label_muted')}>
              {label || children}
            </span>
          )}
          {!!description && <span className="Toggle-description">{description}</span>}
        </label>
      );
    },
  ),
);

export default Toggle;
