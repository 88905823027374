import type { Operation } from 'oas/operation';
import type { HttpMethods } from 'oas/types';

import { getStatusCodeMessage } from '@readme/http-status-codes';
import Oas from 'oas';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useFormatResponseLogCode from '@core/hooks/useFormatResponseLogCode';
import { useAPIDesignerStore } from '@core/store';
import { safelyStringifyJSON } from '@core/utils/json';

import type { ExtendedMediaTypeExample } from '@ui/API/Response';
import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';

import APIDesignerTab from '../components/APIDesignerTab';

import classes from './style.module.scss';

const AVAILABLE_CONTENT_TYPES = [
  'application/json',
  'application/xml',
  'text/plain',
  'text/html',
  'application/octet-stream',
];

export type CompiledResponseExamples = ReturnType<typeof compileResponseExamples>;

function isEmptyCodeExample(code) {
  return !code || code === '""';
}

function compileResponseExamples(
  acc: Map<string, Map<string, ExtendedMediaTypeExample>>,
  response: Operation['responseExamples'][number],
) {
  Object.entries(response.mediaTypes).forEach(([mediaType, mediaTypeExamples]) => {
    const examples = new Map<string, ExtendedMediaTypeExample>();

    mediaTypeExamples.forEach(example => {
      if (isEmptyCodeExample(example.value)) {
        return;
      }

      // Ensure that each have a unique key within the map by concatenating their HTTP status with
      // their `summary` (if present -- we're using `filter(Boolean)` because `summary` is optional
      // on examples within the OpenAPI spec).
      const uniqKey = [response.status, example.summary].filter(Boolean).join('_');
      examples.set(uniqKey, {
        ...example,
        mediaType,
        code: example.value as string,
      });
    });

    // Generate an example of the status message so we can display render response examples that
    // **only** have header examples
    if (response.onlyHeaders && !mediaTypeExamples.length && mediaType === '*/*') {
      const uniqKey = response.status;
      const example: ExtendedMediaTypeExample = {
        code: getStatusCodeMessage(response.status),
        value: undefined,
        mediaType,
      };

      acc.set(mediaType, new Map([[uniqKey, example]]));
    }

    // Because we're keying our map off of the media type, but examples are processed by HTTP code
    // we don't want to overwrite already coolated examples for a previous HTTP code when
    // processing the one after it (and the one after it, etc.).
    if (!acc.has(mediaType)) {
      acc.set(mediaType, examples);
    } else {
      acc.set(
        mediaType,
        new Map([
          ...Array.from((acc as Map<string, Map<string, ExtendedMediaTypeExample>>).get(mediaType) || []),
          ...Array.from(examples),
        ]),
      );
    }
  });

  return acc;
}

interface ResponseExampleEditorProps {
  /** Whether the editor should be disabled (i.e. if endpoint has limited functionality) */
  disabled?: boolean;
}

const ResponseExampleEditor = ({ disabled }: ResponseExampleEditorProps) => {
  const bem = useClassy(classes, 'ResponseExampleEditor');

  const [oasInStore, getCurrentOperation, addExample, selectedExample, updateContentType, updateSnippet] =
    useAPIDesignerStore(s => [
      s.apiObject?.schema,
      s.getCurrentOperation,
      s.responseExampleEditor.addResponseExample,
      s.responseExampleEditor.selectedExample,
      s.responseExampleEditor.updateContentType,
      s.responseExampleEditor.updateSnippet,
    ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const operationInStore = useMemo(() => getCurrentOperation(), [getCurrentOperation, oasInStore]);

  // This and `compileResponseExamples` are pulled from `API/Response/index.tsx`
  // There's logic in there for callbacks but not bringing that in for the first iteration.
  const responseExamples = useMemo(
    () => {
      let oas: Oas;

      try {
        oas = new Oas(JSON.parse(JSON.stringify(oasInStore || '{}')));
      } catch (e) {
        oas = new Oas('{}');
      }

      const operation = oas.operation(
        operationInStore?.currentPath || '/',
        operationInStore?.currentMethod as HttpMethods,
      );

      const examples = operation.getResponseExamples();

      // Iterate through all HTTP codes that this operation responds with and flatten them out into
      // a map of media types -> HTTP codes -> examples
      const res = examples.reduce((acc, response) => {
        return compileResponseExamples(acc, response);
      }, new Map<string, Map<string, ExtendedMediaTypeExample>>());

      // Filter out any empty media type example maps.
      res.forEach((ex, mediaType) => {
        if ('size' in ex && !ex.size) {
          res.delete(mediaType);
        }
      });

      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Exhaustive hooks rule wants `operation` here.
    [operationInStore?.currentPath, operationInStore?.currentMethod, operationInStore.operation?.responses],
  );

  const hasExamples = useMemo(() => {
    return responseExamples.size > 0;
  }, [responseExamples.size]);

  const currentId = selectedExample?.id;
  const currentContentType = selectedExample?.mediaType;

  const providedLanguage = useMemo(() => {
    switch (currentContentType) {
      case 'application/json':
        return 'json';
      case 'application/xml':
        return 'xml';
      case 'text/plain':
      case 'text/html':
        return 'text';
      default:
        return 'json';
    }
  }, [currentContentType]);

  const code: string = useMemo(() => {
    let currentCode = responseExamples?.get(currentContentType || '')?.get(currentId || '')?.code || '';
    if (typeof currentCode === 'object') {
      currentCode = safelyStringifyJSON(currentCode) || '';
    }
    return currentCode;
  }, [currentContentType, currentId, responseExamples]);

  const { formattedCode } = useFormatResponseLogCode(code, providedLanguage);

  const codeSnippetFooter = useMemo(() => {
    if (!selectedExample) return undefined;

    return (
      <div className={bem('-footer-button-container')}>
        <Dropdown className={bem('-footer-button')} trigger="click">
          <Button dropdown ghost kind="secondary" size="sm">
            {selectedExample.mediaType}
          </Button>
          <Menu>
            {AVAILABLE_CONTENT_TYPES.map(contentType => {
              return (
                <MenuItem
                  key={contentType}
                  active={selectedExample.mediaType === contentType}
                  onClick={() => updateContentType(contentType)}
                >
                  {contentType}
                </MenuItem>
              );
            })}
          </Menu>
        </Dropdown>
      </div>
    );
  }, [bem, selectedExample, updateContentType]);

  return (
    <div className={bem('&', disabled && '_disabled')} inert={disabled ? '' : undefined}>
      <Flex align="center" className={bem('-header')} gap="0" justify="start" tag="header">
        {!!hasExamples && (
          <Flex className={bem('-header-tabs')} gap="0" justify="start">
            {Boolean(responseExamples.size) && (
              <>
                {[...responseExamples].map(([mediaType, mediaTypeExamples]) => {
                  return (
                    <React.Fragment key={mediaType}>
                      {[...mediaTypeExamples].map(([key, value]) => {
                        const [statusCode] = key.split('_');
                        return (
                          <APIDesignerTab
                            key={key}
                            example={{
                              key,
                              mediaType,
                              namedId: value.title,
                              responseExamples,
                              selectedExampleType: 'response',
                              statusCode,
                            }}
                            theme="light"
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Flex>
        )}
        <button className={bem('-header-add')} onClick={() => addExample()} type="button">
          <Icon color="gray70" name="plus" />
        </button>
      </Flex>

      {hasExamples ? (
        <CodeSnippet
          className={bem('-code')}
          code={formattedCode || ''}
          editorProps={{ onChange: updateSnippet }}
          footer={codeSnippetFooter}
          language={providedLanguage}
          options={{
            dark: true,
            foldGutter: true,
            highlightMode: true,
            inputStyle: 'contenteditable' as const,
          }}
        />
      ) : (
        <Flex align="center" className={bem('-initial-state')} justify="center">
          Add example response
        </Flex>
      )}
    </div>
  );
};

export default ResponseExampleEditor;
