import type { SchemaFormat, SchemaType } from '../TypeMenu';
import type { EndpointDataType } from '@readme/api/src/mappings/page/reference/types';
import type { RequestBodyObject, SchemaObject } from 'oas/types';
import type { OpenAPIV3 } from 'openapi-types';

import produce from 'immer';
import Oas from 'oas';
import React, { useCallback, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useAPIDesignerStore } from '@core/store';

import APISectionHeader from '@ui/API/SectionHeader';
import TypeMenu, { renderSchemaType } from '@ui/APIDesigner/TypeMenu';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';

import useApiDesignerValidation from '../hooks/useApiDesignerValidation';
import apiDesignerClasses from '../style.module.scss';

import classes from './style.module.scss';

type PropertyType = Record<string, SchemaObject>;

interface SchemaTypes {
  add: () => void;
  allOtherParameters: string[];
  isTopLevel?: boolean;
  onChange: ($TSFixMe, string) => void;
  remove: (idx: string) => void;
  schema: {
    default?: string;
    description?: string;
    format?: SchemaFormat;
    isRequired?: boolean;
    items?: OpenAPIV3.ArraySchemaObject['items'] & OpenAPIV3.SchemaObject;
    name?: string;
    properties?: PropertyType;
    required?: string[];
    type: SchemaType;
    'x-readme-id': string;
  };
}

const Schema = ({ add, allOtherParameters, onChange, remove, schema, isTopLevel }: SchemaTypes) => {
  const bem = useClassy(apiDesignerClasses, 'Parameter');
  const [removeRequestBody] = useAPIDesignerStore(s => [s.removeRequestBody]);

  const updateValue = useCallback(
    (value, kind: string) => {
      const updatedSchema = { ...schema };
      if (kind === 'name') updatedSchema.name = value;
      if (kind === 'description') updatedSchema.description = value;
      if (kind === 'required') updatedSchema.isRequired = !schema.isRequired;
      if (kind === 'default') updatedSchema.default = value;
      if (kind === 'type') {
        // Only objects have properties
        if (value !== 'object') {
          delete updatedSchema.properties;
          delete updatedSchema.required;
        } else if (value === 'object' && !updatedSchema.properties) {
          updatedSchema.properties = {};
        }

        updatedSchema.type = value.type;
        if (value.format) {
          updatedSchema.format = value.format;
        } else {
          delete updatedSchema.format;
        }

        if (value.items) {
          updatedSchema.items = value.items;
        } else {
          delete updatedSchema.items;
        }
      }
      onChange(updatedSchema, schema['x-readme-id']);
    },
    [onChange, schema],
  );

  const { error: nameError, field: nameFieldProps } = useApiDesignerValidation<string>({
    // Remove the period from the x-readme-id to make it a valid react hook form error key
    name: `parameter-${schema['x-readme-id']?.replace('.', '-')}-name`,
    validate: {
      beforeChange: (value: string) => {
        const isUnique = !allOtherParameters?.includes(value);
        return isUnique || `There is already a parameter with the name '${value}'. Please choose a different name.`;
      },
      required: 'Parameter name is required',
    },
    value: schema.name || '',
    handleChange: val => updateValue(val, 'name'),
  });

  const handleChangeToOAS = useCallback(
    (editSchema, changedId) => {
      const currentSchema = Object.keys(schema.properties || {}).find(p => {
        return schema.properties![p]['x-readme-id'] === changedId;
      });

      // Item is being deleted
      if (!editSchema && currentSchema) {
        const newSchema = produce(schema, draft => {
          if (draft.properties) {
            delete draft.properties[currentSchema];
          }
        });
        onChange(newSchema, schema['x-readme-id']);
        return;
      }

      const newSchema = produce(schema, draft => {
        // I don't think this is possible
        // just making sure ts doesn't complain
        if (!draft.properties) return;

        // Name changed, make sure clean up references to the old one
        if (currentSchema !== undefined && currentSchema !== editSchema.name) {
          delete draft.properties[currentSchema];
          draft.required = draft.required?.filter(k => k !== currentSchema);
        }

        if (editSchema.isRequired) {
          if (draft.required && !draft.required.includes(editSchema.name)) {
            draft.required.push(editSchema.name);
          } else if (!draft.required) {
            // TODO: this code is really confusing as is. We are making sure if the array doesn't exist, we create it
            draft.required = [editSchema.name];
          }
        } else {
          draft.required = draft.required ? draft.required.filter(k => k !== editSchema.name) : [];
          // An empty required array is not valid in OAS
          if (draft.required.length === 0) {
            delete draft.required;
          }
        }

        draft.properties[editSchema.name] = {
          type: editSchema.type,
          format: editSchema.format,
          default: editSchema.default,
          description: editSchema.description,
          properties: editSchema.properties,
          required: editSchema.required,
          items: editSchema.items,
          // TODO: Ideally we wouldnd't want this to end up in the OAS file on save
          // https://linear.app/readme-io/issue/RM-11038/x-readme-id-shouldnt-be-saved-in-oas-file
          'x-readme-id': editSchema['x-readme-id'],
        };
      });
      onChange(newSchema, schema['x-readme-id']);
    },
    [onChange, schema],
  );

  let nestedSchemas;

  if (schema.type === 'object') {
    nestedSchemas = Object.keys(schema.properties || {})
      .sort((a, b) => {
        const sortIdA = schema.properties![a]['x-readme-id'].split('.')[1];
        const sortIdB = schema.properties![b]['x-readme-id'].split('.')[1];
        return sortIdA - sortIdB;
      })
      .map((propertyName, idx, allParameterNames) => {
        const allOtherParametersCurrent = allParameterNames.filter((_, i) => i !== idx);
        const property = schema.properties![propertyName];
        const addSchema = () => {
          // Want to make sure we are creating an id with the same level
          // So if the parent is 2.1, this will make the id 3.0 (assuming there are no other children at this level)
          const newId = `${parseInt(property['x-readme-id'].split('.')[0], 10) + 1}.${
            Object.keys(property.properties || []).length || 0
          }`;
          const schemaToAdd: SchemaTypes['schema'] = {
            name: propertyName,
            description: property.description,
            required: property.required as string[],
            isRequired: schema.required?.includes(propertyName),
            type: property.type as SchemaType,
            default: property.default,
            properties: {
              ...property.properties,
              'new-param': { type: 'string', 'x-readme-id': newId },
            },
            'x-readme-id': property['x-readme-id'],
          };
          handleChangeToOAS(schemaToAdd, newId);
        };

        const removeSchema = key => {
          handleChangeToOAS(undefined, key);
        };

        if (!property) return null;
        const s: SchemaTypes['schema'] = {
          name: propertyName,
          description: property.description,
          required: property.required as string[],
          isRequired: schema.required?.includes(propertyName),
          type: property.type as SchemaType,
          format: property.format as SchemaFormat,
          items: (property as OpenAPIV3.ArraySchemaObject).items,
          default: property.default,
          properties: property.properties as PropertyType,
          'x-readme-id': property['x-readme-id'],
        };
        return (
          <Schema
            key={idx}
            add={addSchema}
            allOtherParameters={allOtherParametersCurrent}
            onChange={handleChangeToOAS}
            remove={removeSchema}
            schema={s}
          />
        );
      });
  }

  return (
    <Flex align="stretch" className={classes.RequestBodyEditor} gap="0" justify="start" layout="col">
      <Flex align="stretch" gap="0" layout="col">
        <div className={classes['RequestBodyEditor-group']}>
          <Flex align="baseline" gap="2px" justify="start">
            {!isTopLevel && (
              <div className={bem('-inputSizer')}>
                <input
                  className={bem('-input', '-input_name', nameError && '-input_error')}
                  data-1p-ignore
                  placeholder="Name"
                  spellCheck="false"
                  {...nameFieldProps}
                />
                <span aria-hidden="true" className={apiDesignerClasses['Parameter-inputSizer-clone']}>
                  {nameFieldProps.value}
                </span>
              </div>
            )}

            <Dropdown justify="start">
              <span className={bem('-input', '-input_type')}>
                {renderSchemaType(schema)}
                {!isTopLevel && <Icon name="chevron-down" />}
              </span>
              {/* TODO: You should be able to set primitive types at the top level */}
              {/* https://linear.app/readme-io/issue/RM-9361/initially-creating-request-bodies */}
              {!isTopLevel && (
                <TypeMenu
                  format={schema.format}
                  setNewType={newType => updateValue(newType, 'type')}
                  shouldShowAllowObject={true}
                  type={schema.type || 'string'}
                />
              )}
            </Dropdown>

            {!isTopLevel && (
              <Flex
                className={bem('-input', '-input_required', schema.isRequired && '-input_required_checked')}
                gap="xs"
                tag="label"
              >
                <span>required</span>
                <input
                  checked={schema.isRequired}
                  className={bem('-input-checkbox')}
                  onChange={e => updateValue(e.currentTarget.value, 'required')}
                  type="checkbox"
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="2px" justify="end">
            {schema.type !== 'object' && (
              <input
                className={bem('-input', '-input_form')}
                name="default"
                onChange={e => updateValue(e.currentTarget.value, 'default')}
                placeholder="Default Value"
                value={schema.default}
              />
            )}
            <Dropdown>
              <Button ghost kind="minimum" size="xs">
                <Icon name="more-vertical" />
              </Button>
              <Menu>
                <MenuItem
                  color="red"
                  icon="icon icon-trash1"
                  onClick={() => (isTopLevel ? removeRequestBody() : remove(schema['x-readme-id']))}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
            {/* TODO: I don't like this placement, but fine for now */}
            {/* https://linear.app/readme-io/issue/RM-9349/cleanup-code-and-ts */}
            {schema.type === 'object' && (
              <Button kind="secondary" onClick={add} outline size="xs">
                <Icon name="plus" />
              </Button>
            )}
          </Flex>
        </div>
        {!isTopLevel && (
          <input
            className={bem('-description')}
            onChange={e => updateValue(e.currentTarget.value, 'description')}
            placeholder="Description"
            value={schema.description || ''}
          />
        )}
        {!!nameError && <span className={bem('-error')}>{nameError}</span>}
      </Flex>
      {nestedSchemas}
    </Flex>
  );
};

interface RequestBodyEditorProps {
  api: EndpointDataType;
  updateAPI: (apiObject: EndpointDataType) => void;
}

const RequestBodyEditor = (props: RequestBodyEditorProps) => {
  const [addEmptyRequestBody] = useAPIDesignerStore(s => [s.addEmptyRequestBody]);
  const { api, updateAPI } = props;
  const oas = new Oas(api.schema);
  const operation = oas.operation(api.path, api.method);

  // TODO: should check if it's a ref here
  // https://linear.app/readme-io/issue/RM-8850/object-definitionsrefs

  // TODO: this really only works if the request body is an object
  // and doesn't really handle other types of request bodies
  // https://linear.app/readme-io/issue/RM-9361/initially-creating-request-bodies
  const requestBody = operation?.schema?.requestBody as RequestBodyObject;

  const schema = useMemo(
    () =>
      requestBody?.content?.['application/json']?.schema
        ? requestBody.content['application/json'].schema
        : { properties: {}, type: 'object' },
    [requestBody?.content],
  );

  const addIdToProperties = useCallback((newSchema: SchemaObject): SchemaTypes['schema'] => {
    const withIds = produce(newSchema, draftSchema => {
      const helper = (s, nestedLevel) => {
        if (s.properties) {
          let counter = 0;
          Object.keys(s.properties).forEach(key => {
            s.properties[key]['x-readme-id'] = s.properties[key]['x-readme-id'] || `${nestedLevel}.${counter}`;
            counter += 1;
            helper(s.properties[key], nestedLevel + 1);
          });
        }
      };
      helper(draftSchema, 0);
    });

    // The recursiveness + produce makes the type here get real wonky
    return withIds as SchemaTypes['schema'];
  }, []);

  const schemaWithIds = addIdToProperties(schema as SchemaObject);

  const updateTopLevelOAS = useCallback(
    newSchema => {
      const newOas = produce(api.schema, draft => {
        if (draft.paths && draft.paths[api.path]) {
          // @ts-ignore TODO: this doesn't work with non-application/json request bodies https://linear.app/readme-io/issue/RM-9573/support-for-request-content-types-other-than-applicationjson
          draft.paths[api.path][api.method].requestBody.content['application/json'].schema = newSchema;
        }
      });
      updateAPI({ ...api, schema: newOas });
    },
    [api, updateAPI],
  );

  const addSchema = useCallback(() => {
    const newSchemas = produce(schemaWithIds, draft => {
      if (!draft.properties) draft.properties = {};
      const newId = `0.${Object.keys(draft.properties).length || 0}`;
      draft.properties[''] = { type: 'string', 'x-readme-id': newId };
    });
    updateTopLevelOAS(newSchemas);
  }, [schemaWithIds, updateTopLevelOAS]);

  const removeSchema = useCallback(
    key => {
      const modifiedSchemas = produce(schema, draft => {
        // TODO: At this point we know it should exist
        // I just don't know how to tell ts that
        // @ts-ignore
        delete draft.properties[key];
      });
      const newOas = produce(api.schema, draft => {
        if (draft.paths && draft.paths[api.path]) {
          // @ts-ignore TODO: this doesn't work with non-application/json request bodies https://linear.app/readme-io/issue/RM-9573/support-for-request-content-types-other-than-applicationjson
          draft.paths![api.path]![api.method]!.requestBody!.content['application/json'].schema = modifiedSchemas;
        }
      });
      updateAPI({ ...api, schema: newOas });
    },
    [api, updateAPI, schema],
  );

  // Only show the request body editor for POST, PUT, and PATCH
  if (api.method !== 'post' && api.method !== 'put' && api.method !== 'patch') {
    return null;
  }

  // Render a way to add an empty request body
  // TODO: Eventually this should support additional request body types https://linear.app/readme-io/issue/RM-9573/support-for-request-content-types-other-than-applicationjson
  if (!operation.schema.requestBody) {
    return (
      <section>
        <APISectionHeader heading={'Request Body'}>
          <Button kind="secondary" onClick={addEmptyRequestBody} outline size="xs">
            <Icon name="plus" />
          </Button>
        </APISectionHeader>
      </section>
    );
  }

  return (
    <section className={classes['RequestBodyEditor-section']}>
      <APISectionHeader heading={'Request Body'} />
      <Schema
        add={addSchema}
        allOtherParameters={Object.keys(schemaWithIds.properties || {})}
        isTopLevel={true}
        onChange={updateTopLevelOAS}
        remove={removeSchema}
        schema={schemaWithIds}
      />
    </section>
  );
};

export default RequestBodyEditor;
