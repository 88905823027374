import type { TippyProps } from '@tippyjs/react';

import React, { useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import pxOrCssValue from '@core/utils/pxOrCssValue';

import Tooltip from '@ui/Tooltip';

import classes from './index.module.scss';

const TOOLTIP_DELAY: TippyProps['delay'] = [500, null];
const TOOLTIP_OFFSET: TippyProps['offset'] = [0, 5];

interface TruncateProps {
  /**
   * The HTML tag to render the truncated text as
   */
  Tag?: React.ElementType;
  /**
   * The text to truncate
   */
  children: React.ReactNode;
  /**
   * Additional class names to apply to the truncated element
   */
  className?: string;
  /**
   * The CSS max-width value of the truncated element. Provide a number to use pixels, or a string to use any
   * valid CSS max-width value.
   */
  maxWidth?: React.CSSProperties['maxWidth'];
}

/**
 * Truncate a text node with an ellipsis when the text node overflows its container
 */
export default function Truncate({ children, className, maxWidth = '100%', Tag = 'span' }: TruncateProps) {
  const ref = useRef<HTMLElement>(null);
  const bem = useClassy(classes, 'Truncate');
  const styles = useMemo(
    () =>
      ({
        '--Truncate-max-width': pxOrCssValue(maxWidth),
      }) as React.CSSProperties,
    [maxWidth],
  );

  return (
    <Tooltip
      arrow={false}
      asTitle
      content={children}
      delay={TOOLTIP_DELAY}
      offset={TOOLTIP_OFFSET}
      onShow={() => {
        const isTruncated = ref.current && ref.current?.offsetWidth < ref.current?.scrollWidth;
        return isTruncated
          ? undefined // Void return value allows the tooltip to show
          : false; // Cancel the tooltip if the text is not truncated
      }}
      placement="bottom"
    >
      <Tag ref={ref} className={bem('&', className)} style={styles}>
        {children}
      </Tag>
    </Tooltip>
  );
}
