/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Props } from './types';

import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { createEditor, Range } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact } from 'slate-react';

import useEnvInfo from '@core/hooks/useEnvInfo';
import useEventListener from '@core/hooks/useEventListener';

import Flex from '@ui/Flex';
import Skeleton from '@ui/Skeleton';

import Container from './Container';
import Contexts from './Contexts';
import {
  DragLayer,
  CodeSettings,
  EmojiMenu,
  ImageMenu,
  InlineToolbar,
  LinkEditor,
  PageMenu,
  RecipeMenu,
  SlashMenu,
  VariableMenu,
  decorate as _decorate,
  onKeyDown as _onKeyDown,
  renderLeaf,
  renderNode,
  useCopyHandler,
  usePasteHandler,
  ReusableContentMenu,
} from './editor';
import { BlockMenuContainer } from './editor/BlockMenu';
import { CodeSettingsProvider } from './editor/CodeSettings/useCodeSettings';
import _onClick from './editor/onClick';
import _onMouseOver from './editor/onMouseOver';
import { useRenderingLibrary } from './editor/RenderingLibraryProvider';
import { replace } from './editor/transforms';
import UrlMenu from './editor/UrlMenu';
import useOnChange from './editor/useOnChange';
import useReadmeEditor from './editor/useReadmeEditor';
import classes from './style.module.scss';
import useClassName from './useClassName';

const defaults = {
  basic: false,
  canUseCustomComponents: false,
  className: '',
  clickableAreaHeight: 9,
  compatibilityMode: false,
  customBlocks: [],
  disallowRecipes: false,
  disallowCustomBlocks: false,
  doc: '',
  domainFull: '',
  imageUpload: true,
  glossaryTerms: [],
  onChange: undefined,
  onCustomBlockSave: undefined,
  onInit: undefined,
  parentSubdomain: '',
  projectBaseUrl: '',
  reusableContentMode: 'default' as Props['reusableContentMode'],
  subdomain: '',
  superhub: false,
  tabIndex: undefined,
  useAPIv2: false,
  useMDX: false,
  useReusableContent: false,
  useTestData: process.env.NODE_ENV === 'test',
  variableDefaults: [],
  version: '',
};

/**
 * A ReadMe-flavored markdown editor
 */
const MarkdownEditor = (props: Props) => {
  const editorProps = { ...defaults, ...props };
  const editableProps = Object.fromEntries(Object.entries(props).filter(([k]) => !(k in defaults)));
  const {
    basic,
    className,
    clickableAreaHeight,
    customBlocks = [],
    disallowCustomBlocks,
    doc,
    domainFull,
    onChange: onChangeCallback,
    onInit,
    projectBaseUrl,
    subdomain,
    tabIndex,
    useAPIv2,
    useMDX,
    version,
  } = editorProps;

  const [components, reusableContent] = useMemo(() => {
    const comps = {};
    const tags = {};

    customBlocks.forEach(block => {
      if (block.type === 'component') {
        comps[block.tag] = block;
      } else {
        tags[block.tag] = block;
      }
    }, {});

    return [comps, { tags, disabled: disallowCustomBlocks }];
  }, [customBlocks, disallowCustomBlocks]);

  const renderingLibrary = useRenderingLibrary();

  // @todo: do we want to rebuild the editor when the doc/value change?!
  const editor = useReadmeEditor(
    useMemo(() => {
      return withReact(withHistory(createEditor()));
    }, []),
    editorProps,
    { components, reusableContent, renderingLibrary },
  );

  const initialValue = useMemo(() => editor.deserialize(doc), [doc, editor]);
  const decorate = useCallback(entry => _decorate(editor, entry), [editor]);

  const onChange = useOnChange(editor, initialValue, onChangeCallback);
  // @note this is to prevent the click from bubbling up to the Container
  // component.
  const onClick = useMemo(() => _onClick(editor), [editor]);
  const onKeyDown = useMemo(() => _onKeyDown({ editor }), [editor]);
  const onCopy = useCopyHandler(editor);
  const onCut = useCopyHandler(editor, true);
  const onDrop = useCallback(() => true, []);
  const onMouseOver = useMemo(() => _onMouseOver({ editor }), [editor]);
  const onPaste = usePasteHandler(editor, domainFull);
  const onSelect = useCallback(() => {
    if (!editor.inlineToolbar) return;
    const { isOpen, open, close } = editor.inlineToolbar;

    if (isOpen) {
      close();
    } else if (editor.selection && !Range.isCollapsed(editor.selection)) {
      open();
    }
  }, [editor.inlineToolbar, editor.selection]);
  const renderElement = useCallback(_props => renderNode(editor, _props), [editor]);

  useEffect(() => onInit?.(editor), [editor, onInit]);

  useEventListener('editor:replace', (event: CustomEvent) => {
    replace(editor, editor.deserialize(event.detail));
  });

  const _className = useClassName('markdown-body', classes.MarkdownEditor, className, 'MarkdownEditor');
  const style = useMemo(
    () => ({
      '--clickable-area-height': `${clickableAreaHeight}em`,
    }),
    [clickableAreaHeight],
  );

  const showCustomBlocks = !disallowCustomBlocks && !basic;

  return (
    <Slate editor={editor} onChange={onChange} value={initialValue}>
      <CodeSettingsProvider>
        <Container style={style as React.CSSProperties}>
          <BlockMenuContainer>
            <Editable
              className={_className}
              data-testid="markdown-editor"
              decorate={decorate}
              onClick={onClick}
              onCopy={onCopy}
              onCut={onCut}
              onDrop={onDrop}
              onKeyDown={onKeyDown}
              onMouseOver={onMouseOver}
              onPaste={onPaste}
              onSelect={onSelect}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              scrollSelectionIntoView={() => {}}
              tabIndex={tabIndex}
              {...editableProps}
            />
          </BlockMenuContainer>
          <LinkEditor projectBaseUrl={projectBaseUrl} state={editor.linkEditor as [any, any]} />
          {!basic && (
            <RecipeMenu
              state={editor.recipeMenu as [unknown, { close: () => void }]}
              subdomain={subdomain}
              useAPIv2={useAPIv2}
              version={version}
            />
          )}
          <ImageMenu />
          <CodeSettings />
          <SlashMenu />
          <InlineToolbar linkEditorOpen={!!editor.linkEditor[0].link} />
          {/**
           * The ReusableContentMenu can be rendered whenever MDX is supported, regardless of supporting Custom Blocks.
           * This is because glossary terms are rendered via this component and we want those to be accessible.
           */}
          {!!(showCustomBlocks || useMDX) && <ReusableContentMenu />}
          <VariableMenu />
          <PageMenu />
          <EmojiMenu />
          <UrlMenu />
        </Container>
        <DragLayer />
      </CodeSettingsProvider>
    </Slate>
  );
};

const EditorWithContexts = (props: Props) => {
  const env = useEnvInfo();
  const [isClient, setIsClient] = useState(env.isTest);

  useEffect(() => {
    setIsClient(env.isClient || env.isTest);
  }, [env.isClient, env.isTest]);

  return isClient ? (
    <Contexts {...props}>
      <MarkdownEditor {...props} />
    </Contexts>
  ) : (
    <Flex layout="col" style={{ padding: 20 }}>
      <Skeleton kind="text" width="80%" />
      <Skeleton kind="text" />
      <Skeleton kind="text" />
    </Flex>
  );
};

export default EditorWithContexts;
