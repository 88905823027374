import type { SegmentItemProps } from './SegmentItem';

import React, { cloneElement, isValidElement, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import pxOrCssValue from '@core/utils/pxOrCssValue';

import { useSegmentContext } from './Context';
import SegmentContextProvider from './Context/Provider';
import classes from './index.module.scss';
import SegmentItem from './SegmentItem';

export interface SegmentProps {
  /**
   * Must be `SegmentItem` components.
   */
  children: React.ReactNode;
  /**
   * If `true`, the buttons will have a circular border-radius.
   */
  circular?: boolean;
  /**
   * The className to apply to the segment container.
   */
  className?: string;
  /**
   * If `true`, the segment take up the full width of its container with evenly spaced buttons.
   */
  fullWidth?: boolean;
  /**
   * The space between the segment buttons.
   */
  gap?: number | string;
  /**
   * If `true`, the segment container will have no background color.
   */
  ghost?: boolean;

  /**
   * Color of the segment buttons. Default is `secondary`.
   */
  kind?: 'primary' | 'secondary';
  /**
   * Callback fired when a segment button is clicked.
   */
  onChange?: (index: number, value?: string) => void;
  /**
   * The index of the item in the segment group that is selected by default.
   */
  selectedIndex?: number;
  /**
   * The size of the segment buttons. Default is `md`.
   */
  size?: 'lg' | 'md' | 'sm' | 'xs';
  /**
   * If `true`, the active segment button will have an underline indicator.
   */
  underline?: boolean;
}

function SegmentContent({
  children,
  className,
  circular = false,
  fullWidth = false,
  gap = 0,
  ghost = false,
  kind = 'secondary',
  onChange,
  size = 'md',
  underline = false,
}: SegmentProps) {
  const bem = useClassy(classes, 'Segment');

  const { highlightPosition } = useSegmentContext();

  const clonedChilden = useMemo(() => {
    const _children = React.Children.toArray(children).reduce((acc: React.ReactNode[], child, index) => {
      // If the child is a SegmentItem
      if (isValidElement<SegmentItemProps>(child) && child.type === SegmentItem) {
        acc.push(
          cloneElement(child, {
            // Apply the controlled props to each SegmentItem
            _index: index,
            onClick: event => {
              child.props.onClick?.(event);
              onChange?.(index, child.props.value);
            },
          }),
        );
      }
      return acc;
    }, []);

    return _children;
  }, [children, onChange]);

  const styles = useMemo(
    () =>
      ({
        '--Segment-highlight-left': `${highlightPosition?.left}px`,
        '--Segment-highlight-width': `${highlightPosition?.width}px`,
        '--Segment-gap': pxOrCssValue(gap),
      }) as React.CSSProperties,
    [gap, highlightPosition?.left, highlightPosition?.width],
  );

  return (
    <div
      className={bem(
        '&',
        `_${kind}`,
        `_${size}`,
        circular && '_circular',
        fullWidth && '_fullWidth',
        ghost && '_ghost',
        highlightPosition && '_animateHighlight',
        underline && '_underline',
        className,
      )}
      role="tablist"
      style={styles}
    >
      {clonedChilden}
    </div>
  );
}

/**
 * Used to pick one choice from a linear set of closely related choices, and
 * immediately apply that selection. Children must be a collection of
 * `SegmentItem` components.
 *
 * @example
 * ```tsx
 * <Segment>
 *   <SegmentItem>Lemonade</SegmentItem>
 *   <SegmentItem>Iced Tea</SegmentItem>
 *   <SegmentItem>Water</SegmentItem>
 * </Segment>
 * ```
 */
function Segment(props: SegmentProps) {
  return (
    <SegmentContextProvider selectedIndex={props.selectedIndex}>
      <SegmentContent {...props} />
    </SegmentContextProvider>
  );
}

export { default as SegmentItem } from './SegmentItem';
export default Segment;
