import type { CustomBlockMenuItem, MenuHeaderItemMap, ReusableContentMenuItem } from './types';

import React, { memo, useEffect, useCallback, useRef, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Editor, type BaseRange, Transforms } from 'slate';
import { useSlateStatic, ReactEditor } from 'slate-react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { ReusableContent, GlossaryTerm, MenuHandle } from '@ui/MarkdownEditor/editor/blocks';
import MenuDropdown from '@ui/MarkdownEditor/editor/MenuDropdown';
import { MenuActionTypes, MenuConfigActionTypes } from '@ui/MarkdownEditor/enums';
import Menu, { MenuHeader, MenuItem } from '@ui/Menu';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';
import useReusableContentMenu from './useReusableContentMenu';

// Height dimension of each item in the list
const ITEM_SIZE = 32;

// Number of rows remaining before triggering data loading on scroll
const ROW_THRESHOLD = 5;

const ReusableContentMenu = () => {
  const editor = useSlateStatic();
  const [
    { selected, filtered, customBlocks, target, open, rangeRef, hasNextPage, isNextPageLoading, shortcut },
    dispatch,
  ] = useReusableContentMenu();

  const selectedItem = filtered[selected];
  const selectedTagOrName = selectedItem
    ? 'tag' in selectedItem
      ? selectedItem.tag
      : 'term' in selectedItem
        ? selectedItem.term
        : null
    : null;
  const menuRef = useRef<HTMLDivElement>(null);

  const bem = useClassy(classes, 'ReusableContentMenu');

  /**
   * Adds menu headers to the filtered array of blocks.
   * We want to insert the headers as items in the array itself in order to play nicely with the VirtualList
   * which expects a flat array of items and applies dynamic styles to each list item.
   *
   * Note: The filtered array is already sorted by type in the reducer. Performing the sort there is more efficient
   * and preserves the order of the fuzzy search results (e.g. if the closest matching search result is a glossary term,
   * that will be the first section in the filtered array).
   */
  const results = useMemo(() => {
    if (!filtered.length) return [];

    const headers: MenuHeaderItemMap = {
      component: { type: 'header', label: 'Components', icon: 'component' },
      content: { type: 'header', label: 'Reusable Content', icon: 'recycle' },
      term: { type: 'header', label: 'Glossary Terms', icon: 'bookmark' },
    };

    // Keeps track of whether we've inserted a header for a given type
    const seenTypes = new Set();

    // Creates a new array without modifying original filtered array
    return filtered.reduce((acc: ReusableContentMenuItem[], block) => {
      // Conditionally add headers for each type of block when the first block of that type is encountered
      if ('type' in block && headers[block.type] && !seenTypes.has(block.type)) {
        acc.push({ ...headers[block.type] });
        seenTypes.add(block.type);
      }
      if ('term' in block && !seenTypes.has('term')) {
        acc.push({ ...headers.term });
        seenTypes.add('term');
      }
      acc.push({ ...block });
      return acc;
    }, []);
  }, [filtered]);

  const onClick = (block: CustomBlockMenuItem) => {
    ReactEditor.focus(editor);

    // TODO: extend logic here to check for type and handle component case
    const { tag } = block;

    if (rangeRef) {
      ReusableContent.operations.insertReusableContent(editor, tag, { at: rangeRef?.current as BaseRange });
    }

    dispatch({ type: MenuActionTypes.close });
  };

  const onClickGlossary = (name: string) => {
    if (!rangeRef?.current) return;

    GlossaryTerm.operations.insertGlossaryTerm(editor, name, { at: rangeRef?.current as BaseRange });

    const entry = Editor.above(editor, { at: rangeRef.current as BaseRange, match: MenuHandle.is });
    if (entry) Transforms.unwrapNodes(editor, { at: entry[1] });

    dispatch({ type: MenuActionTypes.close });

    ReactEditor.focus(editor);
  };

  useEffect(() => {
    if (!open || !menuRef?.current) return;

    const menuItem = [0, 0, selected].reduce((node: Element, index) => node?.children[index], menuRef.current);

    if (!menuItem) return;
    if (!menuItem.scrollIntoView) return;

    menuItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [open, selected]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < results.length,
    [hasNextPage, results.length],
  );

  const itemCount = hasNextPage ? results.length + 1 : results.length;
  const loadMoreItems = isNextPageLoading ? () => {} : () => dispatch({ type: MenuConfigActionTypes.loadNextPage });

  const listHeight = useMemo(() => {
    if (!results?.length) return 0;

    return results.length >= ROW_THRESHOLD + 1
      ? ITEM_SIZE * ROW_THRESHOLD + ITEM_SIZE / 2 // Add half an item to the height to allow for scrolling affordance
      : ITEM_SIZE * results.length;
  }, [results]);

  if (editor.props.disallowCustomBlocks && !editor.props.useMDX) return null;

  return results.length ? (
    <MenuDropdown className={bem('&')} open={open} target={target}>
      <Menu ref={menuRef} className={bem('-menu')} data-testid="reusable-content-menu" role="menu">
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={ROW_THRESHOLD}
        >
          {({ onItemsRendered, ref }) => (
            <VirtualList
              ref={ref}
              height={listHeight}
              itemCount={itemCount}
              itemData={results}
              itemSize={ITEM_SIZE}
              onItemsRendered={onItemsRendered}
              width="100%"
            >
              {({ index, style }) => {
                const block = results[index];
                if (!block) {
                  return (
                    <MenuItem key="loading" className={bem('-menu-results', 'loading')} disabled style={style}>
                      <Spinner />
                    </MenuItem>
                  );
                }

                if ('type' in block && block.type === 'header') {
                  return (
                    <MenuHeader key={block.label} className={bem('-menu-results-header')} style={style}>
                      <Flex align="center" gap="xs" justify="start">
                        <Icon name={block.icon} />
                        <span>{block.label}</span>
                      </Flex>
                    </MenuHeader>
                  );
                }

                const isRC = 'tag' in block;
                const key = isRC ? block.tag : block.term;

                return (
                  <MenuItem
                    key={key}
                    aria-current={key === selectedTagOrName}
                    className={bem('-menu-results', key === selectedTagOrName && 'selected')}
                    onClick={() => {
                      if (isRC) {
                        onClick(block);
                      } else {
                        onClickGlossary(block.term);
                      }
                    }}
                    style={style}
                  >
                    <span className={bem('-menu-results-name')}>{isRC ? block.name : block.term}</span>
                  </MenuItem>
                );
              }}
            </VirtualList>
          )}
        </InfiniteLoader>
      </Menu>
    </MenuDropdown>
  ) : !shortcut && !customBlocks.length ? (
    <MenuDropdown className={bem('&')} open={open} target={target}>
      <Menu ref={menuRef} className={bem('-menu')} data-testid="reusable-content-menu" role="menu">
        <Flex align="center" className={bem('-menu-results_empty')} justify="center">
          No Reusable Content
        </Flex>
      </Menu>
    </MenuDropdown>
  ) : null;
};

export default memo(ReusableContentMenu);
