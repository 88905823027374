import type { Props } from './types';

import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DragAndDropProvider } from '@core/hooks/useDragAndDropContext';

import {
  EmojiMenuProvider,
  PageMenuProvider,
  ReusableContentMenuProvider,
  ReusableContentModalProvider,
  SlashMenuProvider,
  VariableMenuProvider,
} from './editor';
import { ImageMenuProvider } from './editor/ImageMenu';
import RenderingLibraryProvider from './editor/RenderingLibraryProvider';
import { UrlMenuProvider } from './editor/UrlMenu/useUrlMenu';

const Contexts = ({
  basic = false,
  canUseCustomComponents = false,
  children,
  disallowHtmlBlocks,
  disallowRecipes,
  disallowCustomBlocks = false,
  glossaryTerms = [],
  subdomain = '',
  useAPIv2 = false,
  useMDX = false,
  useReusableContent = false,
  useTestData = false,
  variableDefaults = [],
  version = '',
}: Props) => {
  return (
    <ReusableContentMenuProvider
      basic={basic}
      canUseCustomComponents={canUseCustomComponents}
      disallowCustomBlocks={disallowCustomBlocks}
      glossaryTerms={glossaryTerms}
      subdomain={subdomain}
      useMDX={useMDX}
      useReusableContent={useReusableContent}
      useTestData={useTestData}
      version={version}
    >
      <ReusableContentModalProvider enabled={!disallowCustomBlocks}>
        <DragAndDropProvider backend={HTML5Backend}>
          <PageMenuProvider
            basic={basic}
            subdomain={subdomain}
            useAPIv2={useAPIv2}
            useTestData={useTestData}
            version={version}
          >
            <SlashMenuProvider
              basic={basic}
              disallowHtmlBlocks={disallowHtmlBlocks}
              disallowRecipes={disallowRecipes}
              showCustomBlocks={!disallowCustomBlocks}
              useMDX={useMDX}
            >
              <VariableMenuProvider
                glossaryTerms={glossaryTerms}
                useMDX={useMDX}
                useTestData={useTestData}
                variableDefaults={variableDefaults}
              >
                <EmojiMenuProvider>
                  <UrlMenuProvider>
                    <ImageMenuProvider>
                      <RenderingLibraryProvider useMDX={useMDX}>{children}</RenderingLibraryProvider>
                    </ImageMenuProvider>
                  </UrlMenuProvider>
                </EmojiMenuProvider>
              </VariableMenuProvider>
            </SlashMenuProvider>
          </PageMenuProvider>
        </DragAndDropProvider>
      </ReusableContentModalProvider>
    </ReusableContentMenuProvider>
  );
};

export default React.memo(Contexts);
