import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/components/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import type { PageNavCategoryProps } from '@ui/Dash/PageNav';
import { PageNavCategory } from '@ui/Dash/PageNav';

import { useCollapsedStateContext } from '../CollapsedState';
import { useConfirmDeletionContext } from '../ConfirmDeletion';
import { useDisabledStateContext } from '../DisabledStateContext';

import { SidebarNavNewPage } from './NewPage';
import { SidebarNavPages } from './Pages';

/**
 * Top-level category title for custom pages.
 */
export const customPageCategory = 'Custom Pages';

/**
 * Top-level category title for changelogs.
 */
export const changelogCategory = 'Changelogs';

export interface SidebarNavCategoryProps {
  category: GitSidebarCategory;
  index: number;
}

/**
 * Overrides the default context actions for the sidebar category.
 */
const configureActions: PageNavCategoryProps['configureActions'] = {
  'move-toggle': { hidden: true },
};

export const SidebarNavCategory = React.memo(function SidebarNavCategory({ category, index }: SidebarNavCategoryProps) {
  const { routeParentCategory } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();
  const [categories, isCreateNewPage, renameCategory, reorderCategory, routeSection] = useSuperHubStore(s => [
    s.sidebar.data,
    s.editor.isCreateNewPage,
    s.sidebar.renameCategory,
    s.sidebar.reorderCategory,
    s.routeSection,
  ]);
  const { setPendingDelete } = useConfirmDeletionContext();
  const { collapsedStateById, updateCollapsedStateById } = useCollapsedStateContext();
  const isSidebarDisabled = useDisabledStateContext();
  const [editable, setEditable] = useState(false);

  const hasNewPage =
    isCreateNewPage && [routeParentCategory, customPageCategory, changelogCategory].includes(category.title);

  const handleAction = useCallback<NonNullable<PageNavCategoryProps['onAction']>>(
    ({ action }) => {
      switch (action) {
        case 'rename':
          setEditable(true);
          break;
        case 'delete':
          setPendingDelete(category);
          break;
        case 'add':
          browserRouterHistory.push(`/create/${routeSection}/${category.title}`);
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn(`Unhandled action: "${action}"`);
          break;
      }
    },
    [browserRouterHistory, category, routeSection, setPendingDelete],
  );

  const handleEdit = useCallback<NonNullable<PageNavCategoryProps['onEdit']>>(
    ({ label: newTitle, isValid }) => {
      // The esc button should exit editing mode WITHOUT submitting. Verify
      // title isn't empty and has changed. Otherwise exit without saving.
      if (!isValid) {
        setEditable(false);
        return undefined;
      }

      // Validate category name to prevent duplicate titles.
      const isDuplicateTitle = categories.some(c => c.title === newTitle);
      if (isDuplicateTitle) {
        return 'Category title is already taken.';
      }

      setEditable(false);
      renameCategory(category, newTitle);
      return undefined;
    },
    [categories, category, renameCategory],
  );

  const handleMove = useCallback<NonNullable<PageNavCategoryProps['onMove']>>(
    ({ source, target }) => {
      reorderCategory(source.id, target.position);
    },
    [reorderCategory],
  );

  const handleToggle = useCallback<NonNullable<PageNavCategoryProps['onToggle']>>(
    ({ id, isOpen }) => {
      if (!id) return;
      updateCollapsedStateById(id, isOpen);
    },
    [updateCollapsedStateById],
  );

  // We memoize the children prop to PageNavItem in order to prevent unnecessary
  // re-rendering because it is currently expensive to re-render.
  const memoContent = useMemo(() => {
    return (
      <>
        <SidebarNavPages pages={category.pages} />
        <SidebarNavNewPage parent={category.title} />
      </>
    );
  }, [category.pages, category.title]);

  /**
   * Whether or not the category should be collapsible or have actions.
   * This is determined by the route section and the category title
   */
  const isLimitedCategory = useMemo(() => {
    const isChangelogCategory = routeSection === 'changelog' && category.title === changelogCategory;
    const isCustomPageCategory = routeSection === 'page' && category.title === customPageCategory;

    return isChangelogCategory || isCustomPageCategory;
  }, [category.title, routeSection]);

  return (
    <PageNavCategory
      collapsible={!isLimitedCategory}
      configureActions={configureActions}
      disabled={isSidebarDisabled}
      editable={editable}
      id={category.uri}
      label={category.title}
      onAction={handleAction}
      onEdit={handleEdit}
      onMove={handleMove}
      onToggle={handleToggle}
      position={index}
      showActions={!isLimitedCategory}
      startOpened={collapsedStateById?.[category.title] ?? true}
      totalChildPages={(category.pages?.length ?? 0) + (hasNewPage ? 1 : 0)}
    >
      {memoContent}
    </PageNavCategory>
  );
});
