import type { ItemDragEndResult, ItemDropProps, ParentDropProps } from '../DragDrop';
import type { PageNavMetaControlsAction, PageNavMetaControlsProps } from '../MetaControls';
import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageClientSide } from '@readme/backend/models/page/types';

import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import SmartLink from '@ui/SmartLink';

import { PageNavCategoryContext } from '../Category';
import { useIsDragItemHovered, ItemDrop, ParentDrop, useItemDrag } from '../DragDrop';
import PageNavMetaControls from '../MetaControls';
import Tooltip from '../Tooltip';

import EndpointBadge from './EndpointBadge';
import PageNavItemErrorBadge from './ErrorBadge';
import styles from './index.module.scss';
import StatusIcon from './StatusIcon';

/**
 * Returns the ID string that is assigned to a `PageNavItem`'s root element. An
 * `id` string or sidebar page object is passed in to generate a formatted
 * string that can be used to reference this item in the DOM.
 * @example
 * ```tsx
 * <div aria-owns={pages.map(page => getPageNavItemElementId(page)).join(' ')}>
 * ```
 */
export function getPageNavItemElementId<T extends PageClientSide | string>(pageOrId?: T) {
  if (!pageOrId) return undefined;
  const id = typeof pageOrId === 'string' ? pageOrId : (pageOrId._id as string);
  return `PageNavItem-${id}`;
}

/**
 * Helper function to find the maximum nested child level for a given array of page.children
 */
const findMaxNestedChildLevel = children => {
  if (children.length === 0) return 0;

  const traverse = (items, level) => {
    return items.reduce((maxChildLevel, item) => {
      if (Array.isArray(item.children) && item.children.length > 0) {
        return Math.max(maxChildLevel, traverse(item.children, level + 1));
      }
      return maxChildLevel;
    }, level);
  };

  return traverse(children, 1);
};

/**
 * Contains information about an item that allows nested items to be aware of
 * who its parent item is.
 */
export const PageNavItemContext = createContext<{
  /** Parent category ID of this item. */
  categoryId?: string;
  /** Item ID */
  id?: string;

  /**
   * Keeps track of this item's nested level depth starting at the 0th level.
   * Each child page can then deduce which level its at by simply incrementing
   * its parent level. Default value intentionally starts at -1 to ensure the
   * first layer of pages starts at the 0th level.
   */
  level: number;
}>({ categoryId: undefined, id: undefined, level: -1 });
PageNavItemContext.displayName = 'PageNavItemContext';

export interface PageNavItemProps {
  /**
   * Marks the item as active or selected. This typically means the current item
   * is what's currently navigated to and being displayed.
   */
  active?: boolean;

  /**
   * Top-level parent category ID this item belongs to. This is required data to
   * enable repositioning via drag/drop.
   */
  categoryId?: string;

  /**
   * The child sub-pages that are nested inside this parent page. Consumers
   * are responsible for correctly setting this value to show correct subpage
   * counts and have drag'n'drop correctly limit max nesting level.
   */
  childPages?: GitSidebarPage['pages'] | PageClientSide['children'];

  children?: React.ReactNode;

  className?: string;

  /**
   * Overrides the default contextual actions for this item.
   */
  configureActions?: PageNavMetaControlsProps['configureActions'];

  /**
   * Marks the item as in a deprecated state.
   */
  deprecated?: boolean;

  /**
   * Optional prop to disable Drag and Drop functionality for this item.
   */
  disableDnd?: boolean;

  /**
   * Disables the item and makes it completely non-interactive.
   */
  disabled?: boolean;

  /**
   * For items describing an API endpoint, indicates the RESTful operation.
   */
  endpoint?: 'delete' | 'get' | 'head' | 'options' | 'patch' | 'post' | 'put' | 'trace';

  /**
   * Indicates whether this item has a new sub-page created that has not
   * been saved yet. This is used to show correct subpages count and render
   * "New Subpage" item.
   */
  hasNewSubPage?: boolean;

  /**
   * Absolute or relative URL to navigate to when clicked on. When combined with
   * `type=link`, location is opened in a new window.
   */
  href?: string;

  /**
   * Unique ID used by children to identify which parent it belongs to.
   */
  id?: string;

  /**
   * Indicates a private item that is unpublished and not visible to the public.
   */
  isPrivate?: boolean;

  /**
   * Displayed label.
   */
  label: string;

  /**
   * Indicates the 0-indexed nesting depth with 0 as the root. Every sub-level
   * includes additional indentation to imply nesting.
   */
  level?: number;

  /**
   * Timestamp that this item was last modified. When provided, timestamp is
   * displayed in the context menu.
   */
  modified?: Date | string;

  /**
   * Called when this item's context actions are acted on (e.g. add, delete,
   * duplicate, etc).
   */
  onAction?: ({ id, action }: { action: PageNavMetaControlsAction; id: PageNavItemProps['id'] }) => void;

  /**
   * Called when item is moved to another position or a different category.
   */
  onMove?: (result: ItemDragEndResult) => void;

  /**
   * Called when navigational link is acted upon. Event is cancelable to prevent
   * default behavior.
   */
  onNavigate?: (id: PageNavItemProps['id'], event: React.MouseEvent<HTMLAnchorElement>) => void;

  /**
   * Called when the item is expanded/collapsed.
   */
  onToggle?: ({ id, isOpen }: { id: PageNavItemProps['id']; isOpen: boolean }) => void;

  /**
   * Direct parent category or page ID this item belongs to. This is required in
   * order to enable repositioning via drag/drop.
   */
  parentId?: string;

  /**
   * Zero-based index of this item's position in relation to its parent and
   * other siblings. The first child of any parent starts at index 0.
   */
  position?: number;

  /**
   * Indicates whether the page is in a renderable state or contains some
   * invalid Markdown that prevents it from being edited or displayed.
   */
  renderable?: { error?: string | null | undefined; message?: string | null | undefined; status: boolean };

  /**
   * Allows dragging, dropping and contextual actions like Add, Duplicate,
   * Delete, etc. to be performed. Default is `true`. Disable this to render a
   * fixed page that cannot be dragged, dropped onto or removed.
   */
  showActions?: boolean;

  /**
   * Start in an opened state.
   */
  startOpened?: boolean;

  /**
   * Status indicating whether item was recently created, modified or deleted.
   */
  status?: 'created' | 'deleted' | 'modified' | 'none';

  /**
   * When provided, renders a react router `Link` tag to navigate the parent
   * react router instead of the native broweer.
   */
  to?: string;

  /**
   * Indicates whether the item is manually created, synced, a link or some
   * other type like RealTime pages or deprecated. This also determines which
   * icon is rendered next to the label.
   */
  type?:
    | 'apiDefinitions'
    | 'changelog'
    | 'link'
    | 'linkExternal'
    | 'page'
    | 'realtimeAuthentication'
    | 'realtimeGettingStarted'
    | 'realtimeMyRequests'
    | 'sync';
}

const MAX_NESTING_LEVEL = 2;

/**
 * Items represent individual documentation pages that can be nested inside
 * [`PageNavCategory`](/#/Components/Dash/PageNavCategory) or other
 * [`PageNavItem`](/#/Components/Dash/PageNavItem) blocks. Items
 * must be rendered inside a top-level
 * [`PageNav`](/#/Components/Dash/PageNav) container as it depends
 * on various contexts and providers that are declared there.
 * These are the basic building blocks for creating a tree-like structure inside
 */
const PageNavItem = React.memo(function PageNavItem({
  active = false,
  categoryId: propCategoryId,
  childPages = [],
  children,
  className,
  configureActions = {},
  deprecated = false,
  disableDnd = false,
  disabled = false,
  endpoint,
  hasNewSubPage = false,
  href,
  id,
  isPrivate = false,
  label,
  level: propLevel,
  modified,
  onAction,
  onMove,
  onNavigate,
  onToggle,
  parentId: propParentId,
  position = 0,
  renderable = { status: true },
  showActions = true,
  startOpened = false,
  status = 'none',
  to,
  type = 'page',
  ...elementProps
}: PageNavItemProps) {
  const bem = useClassy(styles, 'PageNavItem');
  const { id: dndProviderId } = useDragAndDropContext();

  const rootRef = useRef<HTMLElement>(null);
  const contentRef = useRef<HTMLElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [opened, setOpened] = useState(startOpened);
  const [showControls, setShowControls] = useState(false);

  // Get top-level parent category ID this item belongs to in addition to its
  // immediate parent, which could either be a category or a page item.
  const { id: categoryId = propCategoryId } = useContext(PageNavCategoryContext);
  const { id: parentId = propParentId || categoryId, level: parentLevel } = useContext(PageNavItemContext);

  /**
   * Indicates the 0-indexed nesting depth with 0 as the root. Every sub-level
   * includes additional indentation to imply nesting.
   */
  const level = propLevel ?? parentLevel + 1;

  /**
   * Total number of child pages including new (unsaved) subpages
   */
  const totalChildPages = useMemo(() => {
    // When a new sub-page is being created, add 1 to the total child pages count
    // so we can show correct count alongside the "New Subpage" item.
    const baseCount = childPages?.length ?? 0;
    return hasNewSubPage ? baseCount + 1 : baseCount;
  }, [childPages, hasNewSubPage]);

  /**
   * Determines the deepest child level for this item. This is used to prevent
   * dragging items into a position that would exceed the maximum nesting level.
   */
  const deepestChildLevel = useMemo(() => {
    return findMaxNestedChildLevel(childPages);
  }, [childPages]);

  /**
   * Contains additional configuration for the contextual meta actions. For
   * example, when this item is a subpage, hide the "add" action.
   */
  const configureMetaActions = useMemo(() => {
    const config: PageNavMetaControlsProps['configureActions'] = {
      add: {
        hidden: !(level < MAX_NESTING_LEVEL),
        description: 'Add subpage',
      },
      delete: totalChildPages
        ? {
            description: 'Pages with subpages cannot be deleted.',
            disabled: true,
          }
        : undefined,
      // Allow consumers to override the default configuration
      ...configureActions,
    };

    return config;
  }, [configureActions, level, totalChildPages]);

  // Configure this component to be draggable.
  const { isDragging } = useItemDrag({
    canDrag: () => !!id && showActions && !disabled && !disableDnd,
    elementRef: contentRef,
    type: 'item',
    item: {
      categoryId,
      dndProviderId,
      id,
      hasChildren: !!totalChildPages,
      deepestChildLevel,
      parentId,
      position,
      meta: {
        label,
        status,
        type,
        endpoint,
      },
    },
    end: result => {
      onMove?.(result);
    },
  });

  // Determines when this item should be a valid "parent" dropzone.
  const parentCanDrop = useCallback<NonNullable<ParentDropProps['canDrop']>>(
    ({ item }) => {
      // Prevent drop target when actions are disabled.
      if (!showActions) return false;

      // Only allow dropping items into top-level items or when dragging an item
      // with children that will not exceed the max nesting level.
      const dropIsParent = level < MAX_NESTING_LEVEL;
      const dragIsParent = item.hasChildren;
      const dragExceedsMaxLevel = dragIsParent && (item.deepestChildLevel || 0) + level >= MAX_NESTING_LEVEL;

      return dropIsParent && !dragExceedsMaxLevel;
    },
    [level, showActions],
  );

  // Determines when this item should permit "before" and "after" dropzones.
  const itemCanDrop = useCallback<NonNullable<ItemDropProps['canDrop']>>(
    ({ after, item }) => {
      // Prevent drop target when actions are disabled.
      if (!showActions) return false;

      // Prevent dropping items with children into sub-items
      // if the new nesting level would exceed the max nesting level.
      if ((item.deepestChildLevel || 0) + level > MAX_NESTING_LEVEL) return false;

      // When sub-items exist and is visible, disable the "after" dropzone and
      // defer to the ParentDrop target to cover this use-case.
      // Disable "before" or "after" dropzones that will move the drag source
      // item into a position it's already in. For example, disable the "after"
      // dropzone on the item that's directly before the dragging item. Also,
      // prevent "after" dropzones on parents with visible sub-items.
      const isSameParent = parentId === item.parentId;
      const isBeforeItem = isSameParent && position === item.position - 1;
      const isAfterItem = isSameParent && position === item.position + 1;
      const isOpenParent = !!totalChildPages && opened;
      return after ? !isBeforeItem && !isOpenParent : !isAfterItem;
    },
    [level, opened, parentId, position, showActions, totalChildPages],
  );

  // Called when dragging item is dropped onto this item. Move the dragging item
  // into the first child position of this now parent item.
  const handleParentDrop = useCallback<ParentDropProps['drop']>(() => {
    return {
      categoryId,
      id: id || '',
      parentId: id,
      position: 0,
    };
  }, [categoryId, id]);

  // Called when dragging item is dropped before or after this item. We're
  // reordering this item, but potentially also moving it to a new parent.
  const handleItemDrop = useCallback<ItemDropProps['drop']>(() => {
    return {
      categoryId,
      id: id || '',
      parentId,
      position,
    };
  }, [categoryId, id, parentId, position]);

  const handleMetaControlsAction = useCallback<NonNullable<PageNavMetaControlsProps['onAction']>>(
    (name, category) => {
      switch (name) {
        // Move actions are special and should be bubbled up through our
        // "onMove" handler instead with the same payload as drag/drop moves.
        case 'move': {
          if (!id || !category) break;
          onMove?.({
            source: {
              categoryId,
              id,
              parentId,
              position,
            },
            target: {
              categoryId: category._id,
              id,
              parentId: category._id,
              position: 0,
            },
            type: 'item',
          });
          break;
        }
        default:
          onAction?.({ id, action: name });
          break;
      }
    },
    [categoryId, id, parentId, position, onAction, onMove],
  );

  const { isDragItemHovered } = useIsDragItemHovered({
    accept: 'item',
    disabled: !showActions,
    elementRef: contentRef,
    id,
  });

  const handleClick = useCallback(
    e => {
      onNavigate?.(id, e);
    },
    [id, onNavigate],
  );

  return (
    <PageNavItemContext.Provider value={useMemo(() => ({ categoryId, id, level }), [categoryId, id, level])}>
      <Flex
        ref={rootRef}
        align="stretch"
        className={bem('&', className, isDragging && '_dragging', `_level${level}`)}
        gap="0"
        layout="col"
      >
        <Flex
          ref={contentRef}
          align="center"
          aria-expanded={opened}
          aria-label={label}
          aria-level={level + 2}
          aria-selected={active}
          className={bem(
            '-content',
            (isDragging || isDragItemHovered) && '-content_nohover',
            active && '-content_active',
            deprecated && '-content_deprecated',
            disabled && '-content_disabled',
            isPrivate && '-content_private',
            opened && '-content_opened',
            showControls && '-content_show-controls',
            !renderable?.status && '-content_error',
          )}
          data-testid="page-nav-item-content"
          gap="0"
          justify="start"
          role="treeitem"
          {...elementProps}
        >
          {
            // NOTE(Optimization): We must render our drop targets only as needed,
            // mainly when a dragged item hovers over another item. Otherwise,
            // every item (potentially many) will react to the drag operation,
            // which is expensive and will clog up rendering.
            !!isDragItemHovered && (
              <>
                <ParentDrop
                  accept="item"
                  canDrop={parentCanDrop}
                  drop={handleParentDrop}
                  id={id}
                  setToggle={totalChildPages ? setOpened : undefined}
                />
                <ItemDrop accept="item" canDrop={itemCanDrop} drop={handleItemDrop} id={id} />
              </>
            )
          }
          <span className={bem('-spacer')} data-testid="spacer" style={{ width: `${level * 20}px` }} />
          <Tooltip content={opened ? 'Collapse' : 'Expand'}>
            <Flex
              align="center"
              aria-label="Toggle"
              className={bem('-collapsible-toggle')}
              hidden={!totalChildPages}
              onClick={() => {
                setOpened(!opened);
                onToggle?.({ id, isOpen: !opened });
              }}
              tag="button"
            >
              <Icon className={bem('-collapsible-toggle-icon')} name="chevron-right" size="md" title="" />
            </Flex>
          </Tooltip>
          <SmartLink
            ref={linkRef}
            className={bem('-link')}
            draggable={false}
            href={href}
            id={getPageNavItemElementId(id)}
            onBlur={() => setShowControls(false)}
            onClick={handleClick}
            onFocus={() => setShowControls(true)}
            rel="noreferrer"
            tabIndex={disabled ? -1 : 0}
            to={to}
          >
            <StatusIcon className={bem('-status-icon')} status={status} type={type} />
            <Flex align="center" className={bem('-label')} gap="sm" justify="start" tag="span">
              <Tooltip content={label} placement="top-start">
                <span className={bem('-truncated-label')}>{label}</span>
              </Tooltip>
              {!!totalChildPages && <span className={bem('-count-label')}>{totalChildPages}</span>}
            </Flex>
            <StatusIcon className={bem('-status-icon')} deprecated={deprecated} />
            <EndpointBadge className={bem('-badge')} endpoint={endpoint} />
          </SmartLink>
          {!!showActions && (
            <PageNavMetaControls
              className={bem('-meta-controls')}
              configureActions={configureMetaActions}
              contextMenuRef={linkRef}
              hidden={disabled}
              modified={modified}
              onAction={handleMetaControlsAction}
              onBlur={() => setShowControls(false)}
              onFocus={() => setShowControls(true)}
              type="item"
            />
          )}
          {!renderable?.status && <PageNavItemErrorBadge tooltip="Contains invalid MDX" />}
        </Flex>
        {!!totalChildPages && !!children && (
          <Collapsible className={bem('-children')} opened={!isDragging && opened}>
            {children}
          </Collapsible>
        )}
      </Flex>
    </PageNavItemContext.Provider>
  );
});

export default PageNavItem;
