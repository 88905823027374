import type {
  CustomNamelessTemplateProps,
  CustomArrayFieldTemplateProps,
  CustomArrayItemProps,
  CustomObjectFieldTemplateProps,
  CustomTemplateLabelProps,
  CustomTemplateShellProps,
} from './types';
import type { EnumDescriptions } from '../EnumDescriptionsTable';

import { HTTP_METHOD, type $TSFixMe } from '@readme/iso';
import pluralize from 'pluralize';
import React, { useState, useEffect } from 'react';

import { useReferenceStore } from '@core/store';

import Method from '@ui/API/Method';
import Tooltip from '@ui/Tooltip';

import EnumDescriptionsTable from '../EnumDescriptionsTable';
import DescriptionField from '../Form/components/fields/DescriptionField';
import TitleField from '../Form/components/fields/TitleField';
import { ADDITIONAL_PROPERTY_FLAG, getUiOptions, hasSchemaType, isPolymorphic, retrieveSchema } from '../Form/utils';
import classes from '../style.module.scss';

// When true, adds a data-depth property to each odd/even section to indicate the numeric depth of that element
// See AccordionMultiSchema for the other flag.
const depthDebug = false;

function getDefaultNumFormat(type) {
  if (type === 'integer') return 'int32';
  if (type === 'number') return 'float';
  return '';
}

function isNumType(schema, type, format) {
  return hasSchemaType(schema, type) && (schema.format || getDefaultNumFormat(schema.type)).match(format);
}

function isPrimitiveType(schema) {
  return !hasSchemaType(schema, 'object') && !hasSchemaType(schema, 'array');
}

export function getCustomType(schema) {
  if (hasSchemaType(schema, 'string')) {
    if (schema.format) {
      if (schema.format === 'binary') return 'file';
      if (schema.format === 'dateTime') return 'date-time';

      const supportedStringFormats = [
        'blob',
        'date',
        'date-time',
        'html',
        'json',
        'password',
        'timestamp',
        'uri',
        'url',
      ];

      if (supportedStringFormats.includes(schema.format)) {
        return schema.format;
      }
    }
  }

  if (isNumType(schema, 'integer', /int8|int16|int32|int64/) || isNumType(schema, 'number', /float|double/)) {
    return schema.format;
  }

  return false;
}

export function getTypeLabel(schema, separator = '|', shouldPluralize = false) {
  if ('const' in schema) {
    return 'const';
  }

  const types = Array.isArray(schema.type) ? schema.type : [schema.type];
  let type = types
    .map(schemaType => {
      if (schemaType === 'null') {
        return false;
      }

      if (shouldPluralize) {
        return pluralize(getCustomType(schema) || schemaType);
      }

      return getCustomType(schema) || schemaType;
    })
    .filter(Boolean)
    .join(` ${separator} `);

  if ('items' in schema && 'type' in schema.items) {
    type += ` of ${getTypeLabel(schema.items, 'or', true)}`;
  }

  if (types.includes('null')) {
    type += types.length > 1 ? ` ${separator} null` : 'null';
  }

  return type;
}

// We only ever render this for multischema, which with the accordion never uses a wrapper.
export function CustomTemplateShell(props: CustomTemplateShellProps) {
  return props.children;
}

function CustomTemplateLabel({
  id,
  deprecated,
  description,
  rawDescription,
  label,
  name,
  required,
  onKeyChange = () => {},
  schema,
  depth,
  uiSchema,
  ...props
}: CustomTemplateLabelProps) {
  let { expandContent } = props;

  const isCallbackOrResponseDocs = !!uiSchema['ui:callbackDocs'] || !!uiSchema['ui:responseDocs'];

  const EditLabel = (
    <>
      <div className={classes['ParamAdditional-label']}>
        <div className={classes['Param-type']}>{getTypeLabel(schema)}</div>
        {!!required && <div className={classes['Param-required']}>required</div>}
        {!!deprecated && <Method className={classes['Param-deprecated']} type={HTTP_METHOD.DEPRECATED} />}
      </div>
      {!isCallbackOrResponseDocs && (
        <input
          className={`Input Input_sm ${classes['ParamAdditional-input']}`}
          defaultValue={label}
          id={`${id}-key`}
          onBlur={event => onKeyChange(event.target.value)}
        />
      )}
    </>
  );

  const defaultLabel = typeof schema.default === 'undefined' ? undefined : `Defaults to ${schema.default}`;

  let minMaxLabel: string | undefined;
  const isMinDefined = typeof schema.minimum !== 'undefined';
  const isMaxDefined = typeof schema.maximum !== 'undefined';

  if (isMinDefined && isMaxDefined) {
    minMaxLabel = `${schema.minimum} to ${schema.maximum}`;
  } else if (isMinDefined && !isMaxDefined) {
    minMaxLabel = `${schema.exclusiveMinimum ? '>' : '≥'} ${schema.minimum}`;
  } else if (!isMinDefined && isMaxDefined) {
    minMaxLabel = `${schema.exclusiveMaximum ? '<' : '≤'} ${schema.maximum}`;
  }

  if (schema.type === 'string') {
    const isMinLengthDefined = typeof schema.minLength !== 'undefined';
    const isMaxLengthDefined = typeof schema.maxLength !== 'undefined';

    if (isMinLengthDefined && isMaxLengthDefined) {
      minMaxLabel = `length between ${schema.minLength} and ${schema.maxLength}`;
    } else if (isMinLengthDefined && !isMaxLengthDefined) {
      minMaxLabel = `length ≥ ${schema.minLength}`;
    } else if (!isMinLengthDefined && isMaxLengthDefined) {
      minMaxLabel = `length ≤ ${schema.maxLength}`;
    }
  }

  if (schema.type === 'array') {
    const isMinItemsDefined = typeof schema.minItems !== 'undefined';
    const isMaxItemsDefined = typeof schema.maxItems !== 'undefined';

    if (isMinItemsDefined && isMaxItemsDefined) {
      minMaxLabel = `length between ${schema.minItems} and ${schema.maxItems}`;
    } else if (isMinItemsDefined && !isMaxItemsDefined) {
      minMaxLabel = `length ≥ ${schema.minItems}`;
    } else if (!isMinItemsDefined && isMaxItemsDefined) {
      minMaxLabel = `length ≤ ${schema.maxItems}`;
    }
  }

  const Label = (
    <>
      <label className={classes['Param-name']} htmlFor={id}>
        {name}
      </label>
      <div className={classes['Param-type']}>{getTypeLabel(schema)}</div>
      {!!required && <div className={classes['Param-required']}>required</div>}
      <Tooltip asTitle content={minMaxLabel}>
        <div className={classes['Param-minmax-label']}>{minMaxLabel}</div>
      </Tooltip>
      <Tooltip asTitle content={defaultLabel}>
        <div className={classes['Param-default-label']}>{defaultLabel}</div>
      </Tooltip>
      {!!deprecated && <Method className={classes['Param-deprecated']} type={HTTP_METHOD.DEPRECATED} />}
    </>
  );

  const [expandedParam, setExpandedParam] = useState(false);
  let expandWrapper: JSX.Element | null = null;
  const removeExpandWrapper = function removeExpandWrapper() {
    const isNonPolymorphicObject = hasSchemaType(schema, 'object') && !isPolymorphic(schema);
    // Check for either no properties, or empty properties
    const hasNoProperties = !schema.properties || !Object.keys(schema.properties).length;

    if (isNonPolymorphicObject && hasNoProperties && !schema.additionalProperties) return true;

    return false;
  };

  if (isCallbackOrResponseDocs && schema.additionalProperties && isPrimitiveType(schema.additionalProperties)) {
    expandWrapper = (
      <section className={classes['Param-additionalProperties']}>
        <div className={classes['Param-additionalProperties-label']}>Has additional fields</div>
      </section>
    );
  } else if ((isCallbackOrResponseDocs && depth < 3) || isPolymorphic(schema)) {
    // Exclude response docs at depth 3 or deeper from being auto expanded
    expandWrapper = (
      <section
        className={`${classes['Param-expand']} ${classes['Param-expand_expanded']} ${
          !isPolymorphic(schema) ? (Math.abs(depth) % 2 === 1 ? 'odd' : 'even') : ''
        } ${isPolymorphic(schema) ? classes['Param-multischema'] : ''}`}
        data-depth={depthDebug ? depth : undefined}
      >
        {expandContent}
      </section>
    );
  } else {
    expandWrapper = (
      <section
        className={`${classes['Param-expand']} ${expandedParam ? classes['Param-expand_expanded'] : ''} ${
          Math.abs(depth) % 2 === 1 ? 'odd' : 'even'
        }`}
        data-depth={depthDebug ? depth : undefined}
      >
        <button
          className={`Flex ${classes['Param-expand-button']} ${
            expandedParam ? classes['Param-expand-button_expanded'] : ''
          }`}
          onClick={() => setExpandedParam(!expandedParam)}
          type="button"
        >
          <div className={classes['Param-expand-label']}>
            {label} {getTypeLabel(schema)}
          </div>
          <i className={`${expandedParam ? 'icon-x' : 'icon-plus1'} ${classes['Param-expand-button-icon']}`} />
        </button>
        {!!expandedParam && expandContent}
      </section>
    );
  }

  if (removeExpandWrapper()) {
    expandWrapper = null;
    expandContent = null;
  }

  /**
   * This is specific to Amazon and their custom extensions.
   * Disabling the prop-types ESLint rule here since we're not
   * going to use this property very often.
   *
   * @see RM-8926
   */
  let enumDescriptions: EnumDescriptions[] | undefined =
    ('items' in schema && schema?.items?.['x-docgen-enum-table-extension']) ||
    schema?.['x-docgen-enum-table-extension'];

  if (!enumDescriptions) {
    /**
     * If the Amazon-specific extension is not present, fallback to the Redocly extension
     * and map that object to our expected table data structure
     *
     * @see {@link https://redocly.com/docs/api-reference-docs/specification-extensions/x-enum-descriptions/}
     */
    enumDescriptions = Object.entries(
      ((('items' in schema && schema?.items?.['x-enumDescriptions']) || schema?.['x-enumDescriptions']) as
        | Record<string, string>
        | undefined) || {},
    ).map(([value, enumDescription]) => ({ value, description: enumDescription }));
  }

  return (
    <div className={classes['Param-left']}>
      <div className={classes['Param-header']}>{ADDITIONAL_PROPERTY_FLAG in schema ? EditLabel : Label}</div>
      {!!rawDescription && <div className={classes['Param-description']}>{description}</div>}
      {!!expandContent && !hasSchemaType(schema, 'array') && expandWrapper}
      {!!(enumDescriptions && enumDescriptions.length) && <EnumDescriptionsTable data={enumDescriptions} />}
      {hasSchemaType(schema, 'array') && expandContent}
    </div>
  );
}

export function CustomExpandingTemplate(props: CustomTemplateLabelProps) {
  const { children, uiSchema, depth } = props;
  const expandContent = children ? <div className={classes['Param-children']}>{children}</div> : null;

  return (
    <div className={`${classes.Param} ${Math.abs(depth) >= uiSchema.maxNest ? classes.Collapsed : ''}`}>
      {CustomTemplateLabel({ ...props, expandContent })}
    </div>
  );
}

export function CustomTemplate(props: CustomTemplateLabelProps) {
  const { classNames, children, schema, depth, uiSchema } = props;

  const [isCustomSampleSelected] = useReferenceStore(store => [store.language.isCustomSampleSelected]);

  // Sometimes polymorphic schemas have object types at a level outside of the oneof/anyof
  // In those cases we want to bypass the expanding template
  if (!isPrimitiveType(schema) && !isPolymorphic(schema)) {
    return CustomExpandingTemplate(props);
  }

  return (
    <div className={`${classNames} ${classes.Param} ${Math.abs(depth) >= uiSchema.maxNest ? classes.Collapsed : ''}`}>
      {CustomTemplateLabel({ ...props, expandContent: isPolymorphic(schema) ? children : undefined })}
      {!!children && !isPolymorphic(schema) && !isCustomSampleSelected && (
        <div className={classes['Param-form']}>{children}</div>
      )}
    </div>
  );
}

// Used in the two templates
function CustomArrayItem({
  depth,
  label,
  children,
  className,
  onDropIndexClick = () => {},
  index,
  uiSchema,
}: CustomArrayItemProps) {
  const [expandedParam, setExpandedParam] = useState(true);

  const isCallbackOrResponseDocs = !!uiSchema['ui:callbackDocs'] || !!uiSchema['ui:responseDocs'];

  return (
    <section
      className={`${className} ${classes['Param-expand']} ${expandedParam ? classes['Param-expand_expanded'] : ''} ${
        Math.abs(depth) % 2 === 1 ? 'odd' : 'even'
      }`}
      data-depth={depthDebug ? depth : undefined}
    >
      {!isCallbackOrResponseDocs && (
        <button
          className={`Flex ${classes['Param-expand-button']} ${
            expandedParam ? classes['Param-expand-button_expanded'] : ''
          }`}
          onClick={() => setExpandedParam(!expandedParam)}
          type="button"
        >
          <div className={classes['Param-expand-label']}>
            {label} {getTypeLabel(children?.props?.schema)}
          </div>
          <div>
            <button
              className={`icon-trash1 ${classes['Param-expand-button-icon']} ${classes['Param-expand-button-icon_trash']}`}
              onClick={onDropIndexClick(index)}
              title="Delete"
              type="button"
            />
            <button
              className={`${classes['Param-expand-button-icon']} ${expandedParam ? 'icon-minus1' : 'icon-plus1'}`}
              title={expandedParam ? 'Hide' : 'Show'}
              type="button"
            />
          </div>
        </button>
      )}
      {!!expandedParam && children}
    </section>
  );
}

function emptySchema(schema) {
  if (!schema.format) {
    delete schema.format;
  }

  return Object.keys(schema).length === 0;
}

export function CustomArrayFieldTemplate(props: CustomArrayFieldTemplateProps) {
  const {
    depth,
    label,
    registry,
    formData,
    uiSchema,
    schema,
    className,
    idSchema,
    required,
    canAdd,
    disabled,
    readonly,
    onAddClick = () => {},
  } = props;

  let { title, description, items } = props;
  const { rootSchema } = registry;
  const isCallbackOrResponseDocs = !!uiSchema['ui:callbackDocs'] || !!uiSchema['ui:responseDocs'];

  // If we're rendering response docs we want a single array item to be rendered
  useEffect(() => {
    if (isCallbackOrResponseDocs) {
      onAddClick(
        {
          preventDefault: () => {},
        },
        true,
      );
    }
  }, [isCallbackOrResponseDocs, onAddClick]);

  const itemsSchema = retrieveSchema(schema.items, rootSchema, formData);
  description = uiSchema['ui:description'] || description;
  title = uiSchema['ui:title'] || title;
  if (!Array.isArray(items)) {
    items = [items];
  }

  const renderPolymorphicArrayItems = isCallbackOrResponseDocs && isPolymorphic(itemsSchema);

  return (
    <div className={`${className} ${classes.Fieldset}`} id={idSchema.$id}>
      {!!title && (
        <TitleField
          key={`array-field-title-${idSchema.$id}`}
          id={`${idSchema.$id}__title`}
          required={required}
          title={title}
        />
      )}

      {!!description && (
        <DescriptionField
          key={`array-field-description-${idSchema.$id}`}
          description={description}
          id={`${idSchema.$id}__description`}
        />
      )}

      {!!items &&
        // Response docs should only render children with arrays of objects or oneOfs/anyOfs
        // Everything else will do just fine by the type label
        !!(!isCallbackOrResponseDocs || !isPrimitiveType(itemsSchema) || renderPolymorphicArrayItems) &&
        // Notice we override the Array Item depth with the ArrayFieldTemplate depth
        //  This is intentional to ensure that each item's top level section is rendered matching
        //  The add button.
        // Another option would be to move the section out of the item and into this file, but then
        //  the expansion logic might get a little wonky
        items.map((p: $TSFixMe) => {
          return <CustomArrayItem key={`array-item-list-${idSchema.$id}-${p.key}`} {...p} depth={depth} />;
        })}

      {!!canAdd && !isCallbackOrResponseDocs && !emptySchema(itemsSchema) && (
        <section
          className={`${classes['Param-expand']} ${Math.abs(depth) % 2 === 1 ? 'odd' : 'even'}`}
          data-depth={depthDebug ? depth : undefined}
        >
          <button
            className={`Flex ${classes['Param-expand-button']}`}
            disabled={disabled || readonly}
            onClick={onAddClick}
            type="button"
          >
            <div className="Flex_grow">
              ADD {label} {getTypeLabel(itemsSchema)}
            </div>
            <i className={`icon-plus1 ${classes['Param-expand-button-icon']}`} />
          </button>
        </section>
      )}
    </div>
  );
}

export function CustomNamelessTemplate(props: CustomNamelessTemplateProps) {
  const { children, schema, uiSchema } = props;

  let renderedChildren = children ? (
    <div className={`${classes.Param} ${classes.Param_topLevel}`}>{children}</div>
  ) : null;

  const isCallbackOrResponseDocs = !!uiSchema['ui:callbackDocs'] || !!uiSchema['ui:responseDocs'];

  // Callback and response doc arrays are unintuitive without the type label, so we add that in the following block
  if (isCallbackOrResponseDocs) {
    // Arrays of primitives children are just the array item blocks so we can ignore those
    //  and rely on the label (e.g. array of strings). Sub-objects, oneOfs, anyOfs, and arrays still
    //  need to be rendered so we know what they contain
    if (
      hasSchemaType(schema, 'array') &&
      isPrimitiveType(children?.[0]?.props?.schema?.items) &&
      !isPolymorphic(children?.[0]?.props?.schema?.items)
    ) {
      renderedChildren = null;
    }

    // Primitive data types children are just the input blocks, so we ignore those
    if (isPrimitiveType(schema)) {
      renderedChildren = null;
    }

    return (
      <>
        <div className={`${classes.Param} ${classes.Param_topLevel} ${classes['Param-type']}`}>
          {getTypeLabel(schema)}
        </div>
        {renderedChildren}
      </>
    );
  }

  return renderedChildren;
}

export function CustomObjectFieldTemplate(props: CustomObjectFieldTemplateProps) {
  const canExpand = function canExpand() {
    const { formData, schema, uiSchema } = props;
    if (!schema.additionalProperties) {
      return false;
    }

    const { expandable } = getUiOptions(uiSchema) as { expandable?: boolean };
    if (expandable === false) {
      return expandable;
    }
    // if ui:options.expandable was not explicitly set to false, we can add
    // another property if we have not exceeded maxProperties yet
    if (schema.maxProperties !== undefined) {
      return Object.keys(formData).length < schema.maxProperties;
    }
    return true;
  };

  const { depth, idSchema, uiSchema, properties, disabled, readonly, onAddClick = () => {}, schema } = props;

  const isCallbackOrResponseDocs = !!uiSchema['ui:callbackDocs'] || !!uiSchema['ui:responseDocs'];

  return (
    <div className={classes.Fieldset} id={idSchema.$id}>
      {properties.map(prop => prop.content)}
      {canExpand() && (
        <section
          className={`${classes.ParamAdditional} ${Math.abs(depth) % 2 === 1 ? 'odd' : 'even'}`}
          data-depth={depthDebug ? depth : undefined}
        >
          <button
            className={`Flex ${classes['Param-expand-button']}`}
            disabled={disabled || readonly}
            onClick={onAddClick(schema)}
            type="button"
          >
            <div className="Flex_grow">
              {isCallbackOrResponseDocs && schema.additionalProperties ? 'View Additional Properties' : 'Add Field'}
            </div>
            <i className={`icon-plus1 ${classes['Param-expand-button-icon']}`} />
          </button>
        </section>
      )}
    </div>
  );
}
